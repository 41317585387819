import { authGet, authDelete } from '../../lib';
import { facilities } from './state';
const { actions } = facilities;

export const facilityActions = {
  ...actions,
  getPayersForFacility(facilityId) {
    return async (dispatch) => {
      dispatch(actions.setFacilityLoading({ id: facilityId }));

      const response = await authGet([
        `/facilities/${facilityId}/payers/basic`,
      ]);
      const { data, error } = response;
      if (error) {
        return;
      }
      dispatch(
        actions.setPayersForFacility({
          id: facilityId,
          loading: false,
          hasNoPayers: (data || []).length === 0,
          payers: data,
        }),
      );
    };
  },

  /**
   *
   * @param {object} [options]
   * @param {boolean} options.showAll
   */
  getFacilities(options) {
    const { showAll = false, crossCompany = false } = options || {};
    return async (dispatch) => {
      dispatch(actions.setFacilityOptionsLoading(true));
      const response = await authGet([
        '/Facilities/basic',
        { showAll, crossCompany },
      ]);
      const { data, error } = response;
      if (!error) {
        dispatch(actions.setFacilities(data));
      }
      dispatch(actions.setFacilityOptionsLoading(false));
      return error ? { error } : { data };
    };
  },

  deleteFacility(id) {
    return async () => {
      const response = await authDelete(`facilities/${id}`);
      return response;
    };
  },
};
