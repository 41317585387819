import { authDelete, authGet, authPost } from '../../lib';
import {
  ScreeningTemplate,
  ScreeningListParams,
  ScreeningFieldSettings,
  ScreeningTypeType,
} from './types';

export const screeningsActions = {
  createDefaultTemplate(screeningType: ScreeningTypeType) {
    return async () => {
      const response = await authPost(
        `/screenings/superAdmin/seed/${screeningType}`,
      );
      return response;
    };
  },
  upsertScreeningSettings(
    screeningData: ScreeningTemplate,
    isSuperAdmin = false,
  ) {
    return async () => {
      const response = await authPost(
        `/screenings${route(isSuperAdmin)}`,
        screeningData,
      );
      return response;
    };
  },
  updateScreeningFieldSettings(
    fieldSettings: ScreeningFieldSettings,
    isSuperAdmin = false,
  ) {
    return async () => {
      const response = await authPost(
        `/screenings${route(isSuperAdmin)}/fields`,
        fieldSettings,
      );
      return response;
    };
  },
  deleteScreeningTemplate(screeningTemplateId: number, isSuperAdmin = false) {
    return async () => {
      const response = await authDelete(
        `/screenings${route(isSuperAdmin)}/${screeningTemplateId}`,
      );
      return response;
    };
  },
  getScreeningSettings(screeningTemplateId: number, isSuperAdmin = false) {
    return async () => {
      const response = await authGet(
        `/screenings${route(isSuperAdmin)}/${screeningTemplateId}`,
      );
      return response;
    };
  },
  getScreeningTemplateBasic(screeningTemplateId: number, isSuperAdmin = false) {
    return async () => {
      const response = await authGet(
        `/screenings${route(isSuperAdmin)}/${screeningTemplateId}/basic`,
      );
      return response;
    };
  },
  getScreeningFieldSettings(screeningTemplateId: number, isSuperAdmin = false) {
    return async () => {
      const response = await authGet(
        `/screenings${route(isSuperAdmin)}/${screeningTemplateId}/fields`,
      );
      return response;
    };
  },
  getScreeningSettingsToCreateChild(
    screeningTemplateId: number,
    isSuperAdmin = false,
  ) {
    return async () => {
      const response = await authGet(
        `/screenings${route(isSuperAdmin)}/${screeningTemplateId}/forNewChild`,
      );
      return response;
    };
  },
  getScreeningsListHierarchy(
    filters: ScreeningListParams,
    isSuperAdmin = false,
  ) {
    return async () => {
      const response = await authGet([
        `/screenings${route(isSuperAdmin)}/hierarchy`,
        filters,
      ]);
      return response;
    };
  },
  getScreeningsListCount(
    screeningType: ScreeningTypeType,
    isSuperAdmin = false,
  ) {
    return async () => {
      const response = await authGet([
        `/screenings${route(isSuperAdmin)}/count`,
        screeningType,
      ]);
      return response;
    };
  },
};

function route(isSuperAdmin: boolean) {
  return isSuperAdmin ? '/superAdmin' : '';
}
