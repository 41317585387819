import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { RefreshKeys } from '../../../lib';
import {
  alertActions,
  caseActions,
  caseSelectors,
  uiSelectors,
} from '../../../state';
import DismissReasonModal from '../alertViews/DismissReasonModal';
import { Loader, NoContent, RefreshPrompt } from '../../../components';
import { TaskForm } from '../../../components/modals/TaskForm';
import { useViewVersionListener } from '../../../hooks';
import AlertsListViewItem from '../alertViews/AlertsListViewItem';

export function ToDosList({ containerId }) {
  //hooks
  const dispatch = useDispatch();
  const { id } = useParams();

  //selectors
  const previewId = useSelector(uiSelectors.previewId);
  const todos = useSelector(caseSelectors.todos);

  //state
  const [caseId, setCaseId] = useState();

  const todosViewVersion = useSelector((state) =>
    uiSelectors.viewVersion(state, RefreshKeys.Todos, caseId),
  );

  const [currentItem, setCurrentItem] = useState();
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [editId, setEditId] = useStateWithCallbackInstant(null, (taskId) =>
    setShowTaskForm(!!taskId),
  );
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(
    todos ? (todos.numberOfRows > 15 ? true : false) : false,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [showDismissedReasonModal, setShowDismissedReasonModal] =
    useState(false);
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [showRefreshPrompt, setShowRefreshPrompt] = useState(false);
  const [triggeredInline, setTriggeredInline] = useState(false);

  //consts
  const handleLoadMore = useCallback(
    async (page) => {
      setPageNumber(page);
      await dispatch(caseActions.getTodosList(caseId, page));
    },
    [dispatch, caseId],
  );

  const refreshTodos = useCallback(async () => {
    setShowRefreshPrompt(false);
    setInitialDataLoaded(false);

    await handleLoadMore(1);

    setInitialDataLoaded(true);
  }, [handleLoadMore]);

  const inlineRefresh = useCallback(() => {
    setTriggeredInline(true);
  }, []);

  const handleDone = useCallback(() => {
    dispatch(
      alertActions.setTodoActions({
        data: { ...currentItem, doneOn: new Date() },
        preRefreshCallback: inlineRefresh,
        message: 'Todo set to done',
      }),
    );
  }, [dispatch, currentItem, inlineRefresh]);

  //useEffects
  useEffect(() => {
    if (id) {
      setCaseId(id);
    } else if (previewId) {
      setCaseId(previewId);
    }
  }, [id, previewId]);

  useEffect(() => {
    setHasMore(pageNumber <= Math.ceil(todos.numberOfRows / 15));
  }, [todos, pageNumber]);

  useEffect(() => {
    // only load data if caseId has loaded
    if (caseId) {
      refreshTodos();
    }
  }, [caseId, refreshTodos]);

  useViewVersionListener(
    () => {
      if (triggeredInline) {
        setTriggeredInline(false);
        refreshTodos();
      } else {
        setShowRefreshPrompt(true);
      }
    },
    todosViewVersion,
    caseId,
  );

  useEffect(() => {
    if (currentItem) {
      if (currentItem.todoType === 'SystemGeneratedAlert') {
        setShowDismissedReasonModal(true);
      } else {
        handleDone();
      }
    }
  }, [currentItem, handleDone]);

  return (
    <>
      {!initialDataLoaded ? (
        <Loader />
      ) : (
        <>
          {showRefreshPrompt && <RefreshPrompt handleRefresh={refreshTodos} />}

          {todos?.results?.length > 0 ? (
            <InfiniteScroll
              dataLength={todos.results.length}
              hasMore={hasMore}
              next={() => handleLoadMore(pageNumber + 1)}
              loader={<Loader />}
              scrollableTarget={containerId}
            >
              {todos.results.map((todo, i) => {
                return (
                  <AlertsListViewItem
                    key={i}
                    todo={todo}
                    residentPage
                    setCurrentItem={setCurrentItem}
                    setEditId={setEditId}
                    expandedTaskId={expandedTaskId}
                    setExpandedTaskId={setExpandedTaskId}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <NoContent
              titleText='No results found'
              captionText='Add any tasks here'
              imageLocation='/images/no-notes.svg'
              marginTop={2}
            />
          )}
        </>
      )}
      {showTaskForm && (
        <TaskForm
          handleClose={() => setEditId(null)}
          id={editId}
          open={showTaskForm}
          preRefreshCallback={inlineRefresh}
        />
      )}
      {showDismissedReasonModal && (
        <DismissReasonModal
          handleClose={() => setShowDismissedReasonModal(false)}
          open={showDismissedReasonModal}
          record={currentItem}
          preRefreshCallback={inlineRefresh}
        />
      )}
    </>
  );
}
