import { Chip } from '@mui/material';
import { CustomTooltip } from './CustomTooltip';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  chips: {
    marginTop: 12,
    marginRight: 10,
    background: '#E5E7F5',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  chipIcon: {
    color: 'rgba(0,0,0,0.6)',
  },
}));

export function DocumentChips({ documents, removeDocument }) {
  const classes = useStyles();

  return documents.map((d, i) => {
    return (
      <CustomTooltip title={d?.displayName} placement='bottom' key={i}>
        <Chip
          key={i}
          className={classes.chips}
          label={d?.displayName || ''}
          style={{ overflow: 'hidden' }}
          onDelete={() => removeDocument(i)}
        />
      </CustomTooltip>
    );
  });
}
