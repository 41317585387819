import { useCallback, forwardRef, ReactElement } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

interface BooleanToggleButtonProps {
  disabled?: boolean;
  falseLabel?: string;
  name?: string;
  onChange: (name: string, value: boolean) => void;
  trueLabel?: string;
  value: string | boolean;
}

export const BooleanToggleButton = forwardRef(function BooleanToggleButton(
  props: BooleanToggleButtonProps,
  ref: React.Ref<ReactElement>,
) {
  const {
    disabled = false,
    falseLabel = 'No',
    name,
    onChange,
    trueLabel = 'Yes',
    value,
    ...rest
  } = props;

  const onToggleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const { name, value } = event.target as HTMLButtonElement;
      const trueValue = value === 'true';
      onChange(name, trueValue);
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup
      ref={ref}
      exclusive
      onChange={onToggleChange}
      value={disabled ? null : value}
      size='small'
      disabled={disabled}
      {...rest}
    >
      <ToggleButton key='true' value={true} name={name}>
        {trueLabel}
      </ToggleButton>
      <ToggleButton key='false' value={false} name={name}>
        {falseLabel}
      </ToggleButton>
    </ToggleButtonGroup>
  );
});
