import { useMemo } from 'react';
import { parse, ParsedQuery } from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { ObjectSchema } from 'yup';

/**
 * @returns {object} - Returns the query params as an object
 * using query-string `parse`
 * and useSearchParams from react router
 */

// overloads define the possible combinations of inputs and output
// so callers can know the type of the return value given the parameters that are passed in
export function useGetQueryParams(): ParsedQuery<string>;
export function useGetQueryParams<T extends object>(schema: ObjectSchema): T;
export function useGetQueryParams<T extends object>(
  schema?: ObjectSchema<T>,
): ParsedQuery<string> | T {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const parsedParams = parse(searchParams.toString());
    if (!schema) {
      return parsedParams;
    }

    return schema.cast(parsedParams);
  }, [searchParams, schema]);
}
