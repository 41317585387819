import { Box, Typography } from '@mui/material';
import { Avatar, FlexColumn, CustomTooltip, FlexStart } from '../ui';
import { NameWithStatus } from './NameWithStatus';
import { contactTooltipStyles, getNameLinkProps } from './utils';
import { ResidentNameDisplayProps } from './types';

type Props = {
  facilityName: string;
} & ResidentNameDisplayProps;

export function NameWithStatusAndHover(props: Props) {
  const {
    residentName,
    isResidentActive,
    facilityName,
    ...nameLinkPropValues
  } = props;

  const nameLinkProps = nameLinkPropValues.caseId
    ? getNameLinkProps(nameLinkPropValues)
    : {};

  return (
    <CustomTooltip
      placement='bottom-start'
      componentsProps={{
        tooltip: { sx: contactTooltipStyles },
      }}
      title={
        <FlexStart>
          <Box component='span' mt={0.5}>
            <Avatar
              color='#FE831A'
              style={{ height: '24px', width: '24px', fontSize: 12 }}
              badgeSize={8}
              badgeBorder={1}
              badgeColor={isResidentActive ? 'primary' : 'error'}
              name={residentName}
              variant='rounded'
            />
          </Box>
          <FlexColumn ml={1}>
            <Typography
              variant='subtitle2'
              color='text.primary'
              textTransform='capitalize'
              {...nameLinkProps}
            >
              {residentName}
            </Typography>
            <Typography variant='caption' color='text.disabled'>
              {facilityName}
            </Typography>
          </FlexColumn>
        </FlexStart>
      }
    >
      <NameWithStatus {...props} />
    </CustomTooltip>
  );
}
