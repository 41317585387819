import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { ScreeningTypes, screeningsActions } from '../../../state';
import { generatePath, Routes, SettingsPageViews } from '../../../lib';
import { Loader } from '../../../components';

export default function ScreeningsPage() {
  const isSuperAdmin = !!useMatch(Routes.superAdminScreeningSettings.path);
  const templateRoute = isSuperAdmin
    ? Routes.superAdminScreeningTemplates
    : Routes.adminScreeningTemplates;

  const payerRoute = Routes.settings;
  const theme = useTheme();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [residentTemplateCount, setResidentTemplateCount] = useState<
    number | null
  >(null);

  const dispatch = useDispatch();
  const loadData = useCallback(async () => {
    const response = await dispatch(
      screeningsActions.getScreeningsListCount(
        ScreeningTypes.Resident,
        isSuperAdmin,
      ),
    );
    // @ts-expect-error
    const { data, error } = response;
    if (!error) {
      setResidentTemplateCount(data);
      setDataLoaded(true);
    }
  }, [isSuperAdmin, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return !dataLoaded ? (
    <Loader />
  ) : (
    <Box>
      <Typography variant='h6' marginY={2}>
        Screenings
      </Typography>
      <Box
        padding={2}
        border={`1px solid ${theme.palette.outlineTheme.light}`}
        borderRadius='9px'
      >
        <Typography variant='subtitle1' marginX={1}>
          Resident screening
        </Typography>
        <Typography
          variant='body2'
          color='text.secondary'
          marginX={1}
          marginTop={1}
          marginBottom={2}
        >
          {residentTemplateCount !== null ? (
            `${residentTemplateCount} templates`
          ) : (
            <Typography component='span' fontStyle='italic'>
              Unable to retrieve template count
            </Typography>
          )}
        </Typography>
        <Box display='flex' gap={6}>
          <ManageButton
            title='Manage templates'
            path={generatePath(templateRoute.path, {
              screeningType: ScreeningTypes.Resident,
            })}
          />

          <ManageButton
            title='Manage screening payer settings'
            path={generatePath(
              payerRoute.path,
              {},
              { view: SettingsPageViews.payers },
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

const ManageButton = ({ title, path }: { title: string; path: string }) => (
  <Button sx={{ color: 'primary.main' }} component={Link} to={path}>
    {title}
  </Button>
);
