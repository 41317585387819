import React, {
  useCallback,
  cloneElement,
  Children,
  isValidElement,
  Fragment,
} from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import { ListHead } from './ListHead';

export function DataGrid(props) {
  const {
    classes,
    rowProps = {},
    cellProps,
    expand,
    rowClick,
    mouseEnter,
    mouseLeave,
    rows,
    hasBulkActions,
    ids,
    // onToggleItem,
    resource,
    rowStyle,
    selectedIds,
    rowsPerPage,
    page,
    children,
    order,
    headerProps,
    orderBy,
    updateListParams,
    bulkActionButtons,
    hideHeader,
    metaHeader,
  } = props;

  const dispatch = useDispatch();
  const isSelected = (name) => selectedIds.indexOf(name) !== -1;

  const setSelected = useCallback(
    (newIds) => {
      dispatch(
        updateListParams({
          list: resource,
          params: { selectedIds: newIds },
        }),
      );
    },
    [dispatch, resource, updateListParams],
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleClick = useCallback(
    (event, name) => {
      event.stopPropagation();
      const selectedIndex = selectedIds.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedIds, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedIds.slice(1));
      } else if (selectedIndex === selectedIds.length - 1) {
        newSelected = newSelected.concat(selectedIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedIds.slice(0, selectedIndex),
          selectedIds.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    },
    [selectedIds, setSelected],
  );

  const handleSelectAllClick = (event) => {
    let newIds = [];
    if (event.target.checked) {
      newIds = ids.map((id) => rows[id].id);
    }
    setSelected(newIds);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch(
      updateListParams({
        list: resource,
        params: { order: isAsc ? 'desc' : 'asc', orderBy: property },
      }),
    );
  };

  return (
    <Fragment>
      {!hideHeader && (
        <ListHead
          classes={classes}
          numSelected={selectedIds.length}
          metaHeader={metaHeader}
          headerProps={headerProps}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={ids.length}
          fields={children}
          hasBulkActions={bulkActionButtons !== false}
        />
      )}
      <TableBody>
        {ids.map((id, index) => {
          const row = rows[id];
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
              className={clsx(classes.row, {
                [classes.clickableRow]: rowClick,
              })}
              style={rowStyle && rowStyle(row, index)}
              onClick={
                typeof rowClick === 'function'
                  ? () => rowClick(id, row)
                  : undefined
              }
              onMouseEnter={
                typeof mouseEnter === 'function'
                  ? () => mouseEnter(id, row)
                  : undefined
              }
              onMouseLeave={
                typeof mouseLeave === 'function'
                  ? () => mouseLeave(id, row)
                  : undefined
              }
              hover
              {...rowProps}
            >
              {hasBulkActions && (
                <TableCell padding='checkbox' {...cellProps}>
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={(event) => handleClick(event, row.id)}
                  />
                </TableCell>
              )}
              {Children.map(children, (field, index) => {
                return isValidElement(field) ? (
                  <TableCell
                    align={field.props.align || 'left'}
                    style={field.props.cellStyle}
                    className={field.props.stickyCell ? 'stickyCell' : ''}
                  >
                    {cloneElement(
                      field,
                      {
                        expand,
                        hasBulkActions,
                        id: row.id,
                        key: index,
                        // onToggleItem,
                        record: row,
                        resource,
                        rowClick,
                        selected: selectedIds.includes(row.id),
                      },
                      children,
                    )}
                  </TableCell>
                ) : null;
              })}
              {/* <TableCell component='th' id={labelId} scope='row' padding='none'>
              {row.id}
            </TableCell> */}
            </TableRow>
          );
        })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </Fragment>
  );
}
