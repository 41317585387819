import { useCallback } from 'react';
import clsx from 'clsx';
import { Grow, Toolbar, Typography, Tooltip, IconButton } from '@mui/material';
import { DeleteIcon } from '../../../themes';
import { useStyles } from './toolbar.styles';

export function ListToolbar({
  numSelected,
  bulkActionButtons,
  selectedItemName,
  ...props
}) {
  const classes = useStyles();
  const { /* ids, rows, */ selectedIds } = props;

  return (
    <Grow
      in={!!selectedItemName || numSelected > 0}
      mountOnEnter
      unmountOnExit
      direction='up'
    >
      <Toolbar className={clsx(classes.root, classes.highlight)}>
        <Typography color='info' variant='body2' component='div'>
          {numSelected} {selectedItemName} selected
        </Typography>
        {bulkActionButtons || <BulkDeleteButton selectedIds={selectedIds} />}
      </Toolbar>
    </Grow>
  );
}

export function BulkDeleteButton({ selectedIds }) {
  const handleDelete = useCallback(() => {
    console.log(selectedIds);
    // Implement delete. Optimistic rendering?
  }, [selectedIds]);

  return (
    <Tooltip title='Delete'>
      <IconButton aria-label='delete' onClick={handleDelete} size='large'>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}
