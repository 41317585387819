import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUsersData } from '../../../../hooks';
import { resourceActions, listSelectors } from '../../../../state';
import { FormWithPadding, FormOverlay } from '../../../../components';
import { UpdateScreeningSettings } from './UpdateScreeningSettings';

export interface CompanyPayerUpdateScreeningSettingsFormProps {
  handleClose: (doRefresh?: boolean) => void;
  selectedIds: number[];
}
export function CompanyPayerUpdateScreeningSettingsForm(
  props: CompanyPayerUpdateScreeningSettingsFormProps,
) {
  const { handleClose, selectedIds } = props;
  const dispatch = useDispatch();
  const { companyID } = useGetUsersData();
  const payerList = useSelector((state) =>
    listSelectors.listByResource(state, 'companyPayers'),
  ).data;

  const [submitting, setSubmitting] = useState(false);
  const [payerNames, setPayerNames] = useState<string>();
  const [state, setState] = useState({
    authRequired: null,
    coinsuranceAllowed: null,
  });

  useEffect(() => {
    if (selectedIds && payerList) {
      const payerNames = Object.entries(payerList)
        .filter(([id]) => selectedIds.includes(Number(id)))
        .map(([_, value]: any) => value.name)
        .sort()
        .join(', ');

      setPayerNames(payerNames);
    }
  }, [selectedIds, payerList]);

  const onSave = useCallback(async () => {
    setSubmitting(true);

    const response = await dispatch(
      resourceActions.postResource({
        baseUrl: `/companies/${companyID}/payers/screeningSettingsBulk`,
        payload: {
          payerIDs: selectedIds,
          ...state,
        },
      }),
    );

    setSubmitting(false);

    const { error } = response;

    if (!error) {
      handleClose(true);
    }
  }, [dispatch, selectedIds, handleClose, state, companyID]);

  const onChange = useCallback((name: any, value: any) => {
    setState((prev) => ({ ...prev, [name]: value }));
  }, []);

  return (
    <FormOverlay
      handleClose={handleClose}
      title='Update screening settings'
      onSave={onSave}
      submitting={submitting}
    >
      <FormWithPadding>
        <UpdateScreeningSettings
          selectedIds={selectedIds}
          state={state}
          onChange={onChange}
          payerNames={payerNames}
        />
      </FormWithPadding>
    </FormOverlay>
  );
}
