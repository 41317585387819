import { Divider, styled } from '@mui/material';

interface DividerProps {
  marginBottom?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginTop?: string | number;
  marginX?: string | number;
  marginY?: string | number;
}
export const CustomDivider = styled(Divider)<DividerProps>(
  ({ marginBottom, marginLeft, marginRight, marginTop, marginX, marginY }) => ({
    marginBottom: marginBottom || marginY,
    marginLeft: marginLeft || marginX,
    marginRight: marginRight || marginX,
    marginTop: marginTop || marginY,
  }),
);
