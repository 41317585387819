import { Fragment } from 'react';
import _startCase from 'lodash.startcase';
import { format } from 'date-fns-tz';
import { addDays } from 'date-fns';
import { uploadCaseDocument } from '.';
import { FileTypes, UploadSources } from './FileManager';
import { residentStatusTypes, companyTypes } from './constants';

/**
 * Convert an array of items to an object with the id as the key.
 * @param {Array.<Object>} arr The array
 * @param {Array} [sortIds] An optional empty array that will store the ids
 * in the correct order
 * @returns {Object}
 */
export function arrayToObjByEnumOrId(arr = [], sortIds, idPropName = 'id') {
  return arr.reduce((acc, cur) => {
    const key = cur.enumValue || cur[idPropName] + '';
    acc[key] = cur;
    if (sortIds) {
      sortIds.push(key);
    }
    return acc;
  }, {});
}

/** this returns a sorted list of just the ids */
export function sortById(arr = [], selector = (i) => i.id) {
  return arr.map(selector).sort((a, b) => (a > b ? 1 : -1));
}

/** this returns a copy of the full array sorted by the chosen prop */
export function sortByString(arr = [], selector) {
  return [...arr].sort((m1, m2) => selector(m1).localeCompare(selector(m2)));
}

export function formatAddress(street, rest) {
  const addressPart2 = [rest].map((a) =>
    [a.city, a.stateShortName, a.zip].filter((a) => a).join(' '),
  );

  if (street && addressPart2) {
    street += ', ';
  }

  return (street || '') + addressPart2;
}

export function removeDupesObjArray(curValues, newValues) {
  if (!Array.isArray(curValues) || !Array.isArray(newValues)) {
    return [];
  }
  const curIds = curValues.map((v) => v.id);
  const newIds = newValues.map((v) => v.id);
  const removeDupesIds = Array.from(new Set([...curIds, ...newIds]));
  const toObjById = arrayToObjByEnumOrId([...curValues, ...newValues]);
  return removeDupesIds.map((id) => toObjById[id]);
}

export function validateOnSubmit(props) {
  const { values, schema, setErrors, context = undefined } = props;
  return schema
    .validate(values, { abortEarly: false, context })
    .then(() => Promise.resolve())
    .catch((err) => {
      console.log(err);
      const reduce = err.inner?.reduce((acc, cur) => {
        acc[cur.path] = cur.errors[0];
        return acc;
      }, {});
      setErrors(reduce);
      return Promise.reject(err);
    });
}

// code from https://stackoverflow.com/a/1349426
export function makeuuid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// code from https://stackoverflow.com/a/1421988
export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function formatCurrency(amount, maximumFractionDigits) {
  if (amount === undefined || amount === null) return amount;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
  }).format(amount);
}

export function formatPhone(phone, useParentheses) {
  if (!phone) return phone;
  return phone.replace(
    /(\d{3})(\d{3})(\d{4})/,
    `${useParentheses ? '($1) ' : '$1-'}$2-$3`,
  );
}

export function formatPhoneDots(phone, phoneExt) {
  if (!phone || phone.length !== 10) return phone;

  let formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
  if (phoneExt) {
    formattedPhone += ` ext. ${phoneExt}`;
  }
  return formattedPhone;
}

export function formatSsn(ssn) {
  if (!ssn) return ssn;
  return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
}

export function formatBool(bool, trueVal = 'Yes', falseVal = 'No') {
  return bool === true ? trueVal : bool === false ? falseVal : '';
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/constructor
export function isObject(value) {
  return value?.constructor === Object;
}

/**
 * Loop through an object and set all fields that are null to ''
 * An optional array of fields to skip
 * @param {Object} values
 * @param {array} [fieldsToSkip]
 */
export function convertNullFieldsToEmptyString(
  values,
  fieldsToSkip = [],
  reverse = false,
) {
  if (!values) return values;
  return Object.keys(values).reduce((acc, cur) => {
    if (fieldsToSkip.includes(cur)) {
      acc[cur] = values[cur];
    } else if (reverse) {
      acc[cur] = values[cur] === '' ? null : values[cur];
    } else {
      acc[cur] = values[cur] ?? '';
    }
    return acc;
  }, {});
}

export function removeNulls(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
}

export function getFiltersFromDropdownList(list) {
  if (!list) return {};
  return list.reduce((acc, cur) => {
    const { id, name } = cur;
    acc[id] = name;
    return acc;
  }, {});
}

/**
 * Limit the length of a string with an optional parameter
 * to show an ellipsis at the end of the string.
 * @param {string} string
 * @param {number} limit
 * @param {boolean} showEllipsis [n]
 * @return {string} The truncated string
 */
export function limitStringSize(string, limit, showEllipsis) {
  if (typeof string !== 'string' || string.length <= limit) {
    return string;
  }
  return showEllipsis
    ? string.substr(0, limit - 2) + '...'
    : string.substr(0, limit);
}
export function isValidDateObject(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date, formatStr = 'MM/dd/yyyy') {
  if (date) {
    const dateObject = new Date(date);
    if (isValidDateObject(dateObject)) {
      return format(new Date(date), formatStr);
    }
    return date;
  } else {
    return;
  }
}

export function formatDateRange(
  startDate,
  endDate,
  formatStr = 'MMMM d',
  fullForm = false,
) {
  const startDateDisplay = formatDate(startDate, formatStr);

  if (fullForm) {
    return `${startDateDisplay}-${formatDate(endDate, formatStr)}`;
  }

  // if not fullForm, display the date as concisely as possible

  if (endDate === startDate) {
    return startDateDisplay;
  }

  const endDateFormat =
    endDate.getMonth() === startDate.getMonth() ? 'd' : formatStr;
  const endDateDisplay = formatDate(endDate, endDateFormat);
  return `${startDateDisplay}-${endDateDisplay}`;
}

export function getCaseStatusIconColor(status) {
  switch (status) {
    case residentStatusTypes.NewAdmission:
    case 'Referred':
      return '#6506EE';
    case residentStatusTypes.PlanNeeded:
    case 'FollowingUp':
      return '#FE831A';
    case residentStatusTypes.ShortTerm:
      return '#FFBD07';
    case residentStatusTypes.LongTerm:
      return '#F940B3';
    case 'Retained':
      return '#0514B3';
    case 'ClientLost':
      return '#F940B3';
    case 'NotApplicable':
      return '#D5D8EC';
    default:
  }
}

export function getTodoPriorityColor(priority) {
  if (priority === 'Overdue')
    return { color: '#F91429', backgroundColor: 'rgba(249, 20, 41, .12)' };
  else if (priority === 'DueToday')
    return { color: '#FE831A', backgroundColor: 'rgba(254, 131, 26, .12)' };
  else if (priority === 'DueThisWeek')
    return { color: '#9c09d6', backgroundColor: 'rgba(156, 9,	214, .12)' };
  else return { color: '#5394FA', backgroundColor: 'rgba(83, 148, 250, .12)' };
}

export function formatCaseName(data) {
  const {
    resident: { firstName: primaryFirstName, lastName: primaryLastName } = {},
    spouse,
  } = data;

  const formattedPrimaryFirstName = _startCase(primaryFirstName);
  const formattedPrimaryLastName = _startCase(primaryLastName);

  if (!spouse?.id) {
    return `${formattedPrimaryFirstName} ${formattedPrimaryLastName}`;
  } else {
    const { firstName: secondaryFirstName, lastName: secondaryLastName } =
      spouse;

    const formattedSecondaryFirstName = _startCase(secondaryFirstName);
    const formattedSecondaryLastName = _startCase(secondaryLastName);

    let firstName;
    let lastName;
    if (formattedPrimaryLastName !== formattedSecondaryLastName) {
      firstName = `${formattedPrimaryFirstName} ${formattedPrimaryLastName} & `;
      lastName = `${formattedSecondaryFirstName} ${formattedSecondaryLastName}`;
    } else {
      firstName = `${formattedPrimaryFirstName} & ${formattedSecondaryFirstName}`;
      lastName = formattedPrimaryLastName;
    }
    return `${firstName} ${lastName}`;
  }
}

export function formatMedicareNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-word values.
  const medicareNumber = value.replace(/[^\w]/g, '');

  // medicareNumberLength is used to know when to apply our formatting for the medicare number
  const medicareNumberLength = medicareNumber.length;

  // we need to return the value with no formatting if its less then 5 digits
  if (medicareNumberLength < 5) return medicareNumber;

  // if medicareNumberLength is greater than 4 and less the 8 we start to return
  // the formatted number
  if (medicareNumberLength < 8) {
    return `${medicareNumber.slice(0, 4)}-${medicareNumber.slice(4)}`;
  }

  // finally, if the medicareNumberLength is greater then 8, we add the last
  // bit of formatting and return it.
  return `${medicareNumber.slice(0, 4)}-${medicareNumber.slice(
    4,
    7,
  )}-${medicareNumber.slice(7, 11)}`;
}

export function replaceValues(obj, toReplace, replaceWith) {
  //toReplace: (value, key) => {}, replaceWith: (valueToReplace) => replacementValue
  Object.keys(obj).forEach((key) => {
    if (toReplace(obj[key], key)) {
      obj[key] = replaceWith(obj[key]);
    } else if (obj[key]) {
      if (obj[key] instanceof Array) {
        obj[key].forEach((rec) => replaceValues(rec, toReplace, replaceWith));
      } else if (obj[key] instanceof Object) {
        replaceValues(obj[key], toReplace, replaceWith);
      }
    }
  });
}

export async function uploadDocuments({
  documents,
  customCategory,
  companyID,
  caseId,
  isDocumentsList = false,
}) {
  let docUploads = [];
  const docsForUpload = documents.filter((d) => d.file);

  if (docsForUpload.length) {
    docUploads = await Promise.all(
      docsForUpload.map(async (doc) => {
        return await uploadCaseDocument(doc, companyID, caseId);
      }),
    );
    if (docUploads.some((d) => !d?.url)) {
      return { error: true };
    }
  }

  return {
    documents: [
      ...documents
        //existing docs (with potentially updated names). editing doc name is not avail in documents list
        //also document links- custom name is already set, there is no extension
        .filter((d) => !d.file && (!isDocumentsList || !!d.fileLocation))
        .map(({ fileExtension, name, ...d }) =>
          isDocumentsList || d.fileType === FileTypes.External
            ? d
            : {
                ...d,
                customName: `${name}.${fileExtension}`,
              },
        ),
      //new docs
      ...docUploads.map((d) => ({
        customCategory,
        customName: `${d.name}.${d.fileExtension}`,
        documentType: d.documentType,
        fileLocation: d.url,
        fileType: FileTypes.Internal,
        source: UploadSources.Upload,
      })),
    ],
  };
}

export function validateDocsRequired(documents) {
  const isValid = documents
    .filter((d) => !!d.required)
    .every((d) => !!d.file || !!d.fileLocation);

  return { isValid, errorMessage: '*Please upload all required documents' };
}

export function checkHasNRIWorkflow(facilities = []) {
  return !facilities?.length ? null : facilities.some((f) => f.hasNRIWorkflow);
}

export function checkHasSetting(settings = [], settingType) {
  return (
    settings?.find((s) => s.isActive && s.settingType === settingType) || false
  );
}

export function getPendingStatus(isPending) {
  return isPending ? 'Pending' : 'Active';
}

export function capitalize(words, splitWords = true) {
  if (!words) {
    return words;
  }

  const lowerCase = words.toLowerCase();

  const upperCaseFirstLetter = (word) =>
    word.charAt(0).toUpperCase() + word.substring(1);

  if (!splitWords) {
    return upperCaseFirstLetter(lowerCase);
  }

  var separateWord = lowerCase.split(' ');
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = upperCaseFirstLetter(separateWord[i]);
  }
  return separateWord.join(' ');
}

export function getNoteTypeFromView(view) {
  if (!view) {
    return undefined;
  }
  return view === 'liability'
    ? 'ResidentLiability'
    : view === 'profile'
    ? 'ResidentGeneral'
    : view === 'census'
    ? 'UR'
    : view === 'eligibility'
    ? 'Resources'
    : view === 'documents' || view === 'notes' || view === 'todos'
    ? undefined
    : view[0].toUpperCase() + view.substring(1);
}

export function getNoteTypeColor(type) {
  switch (type) {
    case 'Application':
      return '#FFBD07';
    case 'Clinical':
      return '#F940B3';
    case 'Eligibility':
      return '#0FC076';
    case 'ResidentGeneral':
      return '#096EF8';
    case 'Intake':
      return '#6506EE';
    case 'Legal':
      return '#061bEE';
    case 'ResidentLiability':
      return '#F91429';
    case 'UR':
      return '#FE831A';
    case 'Summary':
      return '#8990B6';
    default:
      return '#000';
  }
}

export function getResidentTextForCompanyType(companyType) {
  return companyType === companyTypes.Facility ? 'resident' : 'client';
}

export function getResidentsList(data, usePersonId = false) {
  const {
    resident: {
      firstName: primaryFirstName,
      lastName: primaryLastName,
      id: primaryResidentID,
      personID: primaryPersonID,
    } = {},
    spouse,
  } = data;

  const list = [
    {
      id: usePersonId ? primaryPersonID : primaryResidentID,
      name: `${_startCase(primaryFirstName)} ${_startCase(primaryLastName)}`,
      isPrimary: true,
    },
  ];
  if (spouse) {
    const {
      firstName: secondaryFirstName,
      lastName: secondaryLastName,
      id: secondaryResidentID,
      personID: secondaryPersonID,
    } = spouse;

    if (usePersonId) {
      if (secondaryPersonID) {
        list.push({
          id: secondaryPersonID,
          name: `${_startCase(secondaryFirstName)} ${_startCase(
            secondaryLastName,
          )}`,
          isPrimary: false,
        });
      }
    } else if (secondaryResidentID) {
      list.push({
        id: secondaryResidentID,
        name: `${_startCase(secondaryFirstName)} ${_startCase(
          secondaryLastName,
        )}`,
        isPrimary: false,
      });
    }
  }

  return list;
}

export function getCaseworkerDisplay(
  caseworkers = [],
  nameSelector = (i) => `${i.firstName} ${i.lastName}`,
) {
  if (caseworkers && caseworkers.length > 0) {
    return `${nameSelector(caseworkers[0])}${
      caseworkers.length > 1 ? ` +${caseworkers.length - 1}` : ``
    }`;
  } else {
    return '';
  }
}

export function getAdditionalNames(
  names = [],
  nameSelector = (i) => `${i.firstName} ${i.lastName}`,
) {
  if (names && names.length > 1) {
    const additional = names.slice(1);
    return (
      <div>
        {additional.map((i, index) =>
          index === additional.length - 1 ? (
            <Fragment key={index}>{nameSelector(i)}</Fragment>
          ) : (
            <Fragment key={index}>
              {nameSelector(i)},<br />
            </Fragment>
          ),
        )}
      </div>
    );
  } else {
    return '';
  }
}

export function getMondayForWeekOfDate(date) {
  const currentDay = date.getDay() || 7; // if date is on a Sunday we want the previous Monday, so convert Sunday to index 7
  const daysFromMonday = currentDay - 1; // Monday is at index 1 of the days of the week
  return addDays(date, -daysFromMonday);
}

export function pluralize(base, count, suffix = 's') {
  return count === 1 ? base : base + suffix;
}

// rest properties are camelCase
export function toRestPropName(value) {
  return value.charAt(0).toLowerCase() + value.substring(1);
}

export function isScrolledToBottom(scrollElement) {
  return (
    scrollElement.scrollHeight -
      scrollElement.scrollTop -
      scrollElement.clientHeight <
    1
  );
}

export const dataTooltipStyles = {
  tooltip: {
    sx: {
      borderRadius: '6px',
      backgroundColor: '#FFF',
      padding: 1.5,
      boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
      color: 'text.primary',
      display: 'flex',
      alignItems: 'center',
    },
  },
};

export function storeSessionItem(name, value) {
  sessionStorage.setItem(name, value);
}

export function getSessionItem(name) {
  return sessionStorage.getItem(name);
}

export function reorderArray(array, from, to) {
  const newArray = array.slice();
  newArray.splice(to, 0, newArray.splice(from, 1)[0]);

  return newArray;
}

export const timeZoneID = Intl.DateTimeFormat().resolvedOptions().timeZone;
