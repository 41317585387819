//https://fonts.google.com/icons?selected=Material+Symbols+Outlined:bid_landscape:FILL@1;wght@400;GRAD@0;opsz@24&icon.query=analytics&icon.size=24&icon.color=%235f6368
export function BidLandscapeIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24px'
      viewBox='0 -960 960 960'
      width='24px'
      fill='#9DA7B9'
      {...props}
    >
      <path d='M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm560-550L518-398 360-556 200-396v114l160-160 162 162 238-270v-120Z' />
    </svg>
  );
}
