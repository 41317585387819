import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  listHeader: {
    ...flex,
    width: '100%',
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 25,
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
  },
  secondaryTitle: {
    fontWeight: 600,
    fontSize: 18,
    width: '100%',
  },
  editIcon: {
    cursor: 'pointer',
    color: theme.palette.outlineTheme.main,
  },
  booleanIcon: {
    color: theme.palette.outlineTheme.main,
  },
  cellBorderLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  listFiltersActions: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  disableGutter: {
    paddingLeft: 0,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  iconMarginLeft: {
    marginLeft: 16,
  },
  customDropdownButton: {
    fontWeight: 'unset',
  },
  listContent: {
    marginTop: 21,
  },
}));

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
