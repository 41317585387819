export function OverrideIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.1444 0L5.85556 0L0 5.85556L0 14.1444L5.85556 20H14.1444L20 14.1444V5.85556L14.1444 0ZM17.7778 13.2222L13.2222 17.7778H6.77778L2.22222 13.2222L2.22222 6.77778L6.77778 2.22222L13.2222 2.22222L17.7778 6.77778V13.2222Z'
        fill='#44516D'
      />
      <path
        d='M7.22266 5.55469V11.6658H13.8893M13.8893 11.6658L11.3252 8.88802M13.8893 11.6658L11.3252 14.4436'
        stroke='#44516D'
        strokeWidth='1.48148'
      />
    </svg>
  );
}
