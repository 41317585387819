import { memo, useState, useCallback, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  styled,
} from '@mui/material';
import {
  ColoredIcon,
  SettingsMenu,
  UserMenu,
  Avatar,
  MuiPageNavLink,
  NoPrint,
  BidLandscapeIcon,
} from '../components';
import {
  companyActions,
  companySelectors,
  uiActions,
  uiSelectors,
} from '../state';
import {
  AssignmentIcon,
  AutoAwesomeIcon,
  CalendarIcon,
  DashboardIcon,
  NotificationsIcon,
  PeopleIcon,
  SchoolIcon,
  SettingsIcon,
  SupportAgentIcon,
  ViewKanbanIcon,
} from '../themes';
import { useStyles } from './sidebar.styles';
import { useGetUsersData } from '../hooks';
import {
  getResidentTextForCompanyType,
  capitalize,
  Routes,
  PermissionClaims,
} from '../lib';

const IntercomBox = styled(Box)(() => ({
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Sidebar = memo(({ isSidebarOpen, authData, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { showSpace } = useIntercom();

  const { firstName, lastName, companyType, PermissionClaim } =
    useGetUsersData();

  const companyDashboardUrl = useSelector(companySelectors.dashboardUrl);

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const handleToggleSidebar = useCallback(() => {
    dispatch(uiActions.toggleSidebar(!isSidebarOpen));
  }, [dispatch, isSidebarOpen]);

  const onClickSettings = useCallback((event) => {
    setSettingsAnchorEl(event.currentTarget);
  }, []);
  const onClickUserMenu = useCallback((event) => {
    setUserAnchorEl(event.currentTarget);
  }, []);

  useEffect(() => {
    if (PermissionClaim.includes(PermissionClaims.CompanyDashboardReadClaim)) {
      dispatch(companyActions.getCompanyDashboardUrl());
    }
  }, [PermissionClaim, dispatch]);

  return (
    <NoPrint>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen,
          }),
          paperAnchorDockedLeft: classes.drawerBorder,
        }}
      >
        <div>
          <div
            aria-label='menu'
            onClick={handleToggleSidebar}
            className={clsx(classes.toolbar, classes.button)}
          >
            <Tooltip
              title={isSidebarOpen ? 'collapse' : 'expand'}
              placement='right'
            >
              <div>
                {isSidebarOpen ? (
                  <img
                    src='/images/aidace-logo-full.svg'
                    alt=''
                    draggable={false}
                  />
                ) : (
                  <img
                    src='/images/aidace-logo-icon.svg'
                    alt=''
                    draggable={false}
                  />
                )}
              </div>
            </Tooltip>
          </div>
          <List>
            <SidebarItem
              to={Routes.dashboard.path}
              text='Dashboard'
              icon={DashboardIcon}
              isSidebarOpen={isSidebarOpen}
            />
            <SidebarItem
              to={Routes.calendar.path}
              text='Calendar'
              icon={CalendarIcon}
              isSidebarOpen={isSidebarOpen}
            />
            <SidebarItem
              to={Routes.alertsPage.path}
              text="To do's"
              icon={TodoIcon}
              isSidebarOpen={isSidebarOpen}
            />
            <SidebarItem
              to={Routes.residentsList.path}
              text={capitalize(
                `${getResidentTextForCompanyType(companyType)}s`,
              )}
              icon={PeopleIcon}
              isSidebarOpen={isSidebarOpen}
            />
            <SidebarItem
              to={Routes.board.path}
              text='Board'
              icon={ViewKanbanIcon}
              isSidebarOpen={isSidebarOpen}
            />
            <SidebarItem
              to={Routes.applications.path}
              text='Applications'
              icon={AssignmentIcon}
              isSidebarOpen={isSidebarOpen}
            />

            {!!companyDashboardUrl && (
              <SidebarItem
                to={Routes.companyDashboards.path}
                text='Analytics'
                icon={BidLandscapeIcon}
                isSidebarOpen={isSidebarOpen}
              />
            )}
          </List>
        </div>
        <List>
          <ListItemButton
            onClick={() => dispatch(uiActions.setAnnouncementLogOpen(true))}
          >
            <ListItemIcon>
              <AutoAwesomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Announcements'}
              className={clsx(classes.listItemText, classes.linkColor)}
            />
          </ListItemButton>
          <ListItemButton onClick={() => showSpace('help')}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Help'}
              className={clsx(classes.listItemText, classes.linkColor)}
            />
          </ListItemButton>
          <ListItemButton onClick={onClickSettings}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Settings'}
              className={clsx(classes.listItemText, classes.linkColor)}
            />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <IntercomBox id='intercom-launcher'>
                <ColoredIcon Icon={SupportAgentIcon} fontSize='medium' />
              </IntercomBox>
            </ListItemIcon>
            <ListItemText
              primary={'Get in touch'}
              className={clsx(classes.listItemText, classes.linkColor)}
            />
          </ListItemButton>
          <ListItemButton onClick={onClickUserMenu} style={{ paddingLeft: 12 }}>
            <ListItemIcon style={{ paddingRight: 27 }}>
              <Avatar
                name={`${firstName} ${lastName}`}
                color={'#FA595F'}
                className={classes.avatar}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${firstName} ${lastName}`}
              className={classes.listItemText}
            />
          </ListItemButton>
        </List>
      </Drawer>
      <SettingsMenu
        anchorEl={settingsAnchorEl}
        setAnchorEl={setSettingsAnchorEl}
      />
      <UserMenu anchorEl={userAnchorEl} setAnchorEl={setUserAnchorEl} />
    </NoPrint>
  );
});

function SidebarItem({ to, text, icon: Icon, isSidebarOpen, disabled }) {
  const classes = useStyles();
  return (
    <MuiPageNavLink to={to} className={classes.link} disabled={disabled}>
      <Tooltip title={isSidebarOpen ? '' : text} placement='right'>
        <ListItemButton disabled={disabled}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </Tooltip>
    </MuiPageNavLink>
  );
}

const TodoIcon = () => {
  const classes = useStyles();
  const todosCount = useSelector(uiSelectors.todosCount);

  return (
    <Badge
      color='secondary'
      badgeContent={todosCount || 0}
      classes={{ badge: classes.badge }}
      overlap='rectangular'
    >
      <NotificationsIcon />
    </Badge>
  );
};
