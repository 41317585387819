import {
  MouseEventHandler,
  ReactElement,
  useState,
  useCallback,
  useRef,
} from 'react';

import {
  Drawer,
  Box,
  Button,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { CustomTooltip, FlexBetween, Loader, CustomDivider } from '../ui';
import { ColoredIcon } from '../common';
import { DeleteConfirmation } from '../modals/DeleteConfirmation';
import { CloseIcon, DeleteOutlinedIcon } from '../../themes';
import { IconType } from '../../lib';

const PADDING_X = '24px';

const SIZES = {
  xsmall: '444px',
  small: '600px',
  medium: '900px',
  large: '1200px',
} as const;

type Props = HeaderProps &
  FooterProps & {
    size?: keyof typeof SIZES;
    pageHeaderHeight?: number;
    noSubmitOnEnter?: boolean;
    hideFooter?: boolean;
    children: ReactElement | ReactElement[] | string;
  };
export function FormOverlay(props: Props) {
  const {
    // general settings
    title,
    size = 'xsmall',
    isEdit,
    submitting,
    errorMessage,
    noSubmitOnEnter = false,
    hideFooter = false,

    // children
    children,

    // close functionality
    handleClose,

    // save functionality
    disableSave,
    saveButtonText = 'Save',
    onSave,
    additionalSaveButtonText,
    additionalOnSave,

    // delete functionality
    deleteTitle,
    deleteMsg,
    handleDelete,
    hideDeleteBtn = false,
    disableDeleteButtonReason,
  }: Props = props;

  const [showDivider, setShowDivider] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollHandler = useCallback(() => {
    const scrollTop = containerRef.current?.scrollTop || 0;
    setShowDivider(scrollTop > 0);
  }, []);

  return (
    <Drawer
      open={true}
      onClose={(_, reason) => {
        if (handleClose && reason !== 'backdropClick') {
          handleClose();
        }
      }}
      anchor='right'
    >
      <OverlayContainer width={SIZES[size]}>
        <Box
          height={hideFooter ? '100vh' : '90vh'}
          alignContent='flex-start'
          overflow='auto'
          ref={containerRef}
          onScroll={scrollHandler}
        >
          <Header
            title={title}
            isEdit={isEdit}
            submitting={submitting}
            handleClose={handleClose}
            // delete functionality
            deleteTitle={deleteTitle}
            deleteMsg={deleteMsg}
            handleDelete={handleDelete}
            hideDeleteBtn={hideDeleteBtn}
            disableDeleteButtonReason={disableDeleteButtonReason}
            showDivider={showDivider}
          />
          <Box paddingY={1}>
            <form
              autoComplete='off'
              onSubmit={noSubmitOnEnter ? (e) => e.preventDefault() : undefined}
            >
              {children}
            </form>
          </Box>
        </Box>
        {!hideFooter && (
          <Footer
            submitting={submitting}
            errorMessage={errorMessage}
            handleClose={handleClose}
            // save functionality
            disableSave={disableSave}
            saveButtonText={saveButtonText}
            onSave={onSave}
            additionalSaveButtonText={additionalSaveButtonText}
            additionalOnSave={additionalOnSave}
          />
        )}
      </OverlayContainer>
    </Drawer>
  );
}

type HeaderProps = {
  title?: string;
  isEdit?: boolean;
  submitting?: boolean;
  handleClose?: () => void;

  // delete functionality
  deleteTitle?: string;
  deleteMsg?: string;
  handleDelete?: MouseEventHandler<HTMLButtonElement>;
  hideDeleteBtn?: boolean;
  disableDeleteButtonReason?: string;
};
const Header = (props: HeaderProps & { showDivider: boolean }) => {
  const {
    deleteMsg,
    deleteTitle,
    disableDeleteButtonReason,
    handleClose,
    handleDelete,
    hideDeleteBtn,
    isEdit,
    submitting,
    title,
    showDivider,
  } = props;

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <Box
      paddingY={2}
      sx={{
        // header section should be frozen
        position: 'sticky',
        top: 0,
        // these properties prevent scrolled elements from "showing through" the header:
        zIndex: 2,
        background: '#FFF',
        paddingLeft: PADDING_X,
        paddingRight: PADDING_X,
      }}
    >
      <FlexBetween>
        {title && <Typography variant='h5'>{title}</Typography>}
        <Box display='flex'>
          {isEdit && !hideDeleteBtn && (
            <CustomTooltip title={disableDeleteButtonReason}>
              <ToolbarButton
                Icon={DeleteOutlinedIcon}
                action={() => setShowDeleteConfirm(true)}
              />
            </CustomTooltip>
          )}
          {handleClose && (
            <ToolbarButton Icon={CloseIcon} action={handleClose} />
          )}
        </Box>
      </FlexBetween>
      {showDivider && <CustomDivider marginX={`-${PADDING_X}`} />}
      {showDeleteConfirm && (
        <DeleteConfirmation
          title={deleteTitle || title || ''}
          text={deleteMsg || ''}
          btnText='Yes, delete it'
          submitting={submitting}
          handleDelete={handleDelete!}
          handleClose={() => setShowDeleteConfirm(false)}
        />
      )}
    </Box>
  );
};

const ToolbarButton = ({
  Icon,
  action,
}: {
  Icon: IconType;
  action: () => void;
}) => (
  <IconButton onClick={action}>
    <ColoredIcon Icon={Icon} iconColor='action.primaryColor' />
  </IconButton>
);

type FooterProps = {
  submitting?: boolean;
  errorMessage?: string;
  handleClose?: () => void;

  // save functionality
  disableSave?: boolean;
  saveButtonText?: string;
  onSave?: () => void;
  additionalSaveButtonText?: string;
  additionalOnSave?: MouseEventHandler<HTMLButtonElement>;
};
const Footer = (props: FooterProps) => {
  const {
    submitting,
    errorMessage,
    handleClose,

    // save functionality
    disableSave,
    saveButtonText = 'Save',
    onSave,
    additionalSaveButtonText,
    additionalOnSave,
  } = props;

  return (
    <Box>
      <CustomDivider marginTop={15} marginX={`-${PADDING_X}`} />
      {submitting && <Loader />}
      <Box display='flex' margin={2} gap={2} justifyContent='right'>
        <Button onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={onSave}
          disabled={submitting || !!disableSave}
        >
          {saveButtonText}
        </Button>
        {additionalSaveButtonText && (
          <Button
            variant='contained'
            onClick={additionalOnSave}
            disabled={submitting || !!disableSave}
          >
            {additionalSaveButtonText}
          </Button>
        )}
      </Box>

      {errorMessage && (
        <Box justifyContent='right'>
          <Typography color='error.main'>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

interface CustomProps {
  width: string;
}
const OverlayContainer = styled(Box)<CustomProps>(({ width }) => ({
  overflowX: 'hidden',
  width: width,
  backgroundColor: '#FFF',
}));
