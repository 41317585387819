import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DeleteIcon,
  CloudUploadIcon,
  FileIcon,
  VisibilityIcon,
} from '../../../themes';
import { DocumentViewModal } from '../../modals/DocumentViewModal';

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingRight: 10,
  },
  uploadIcon: { color: '#D5D8EC' },
  uploadText: { color: '#8990B6' },
  linkText: { color: '#096EF8', fontWeight: 500 },

  text: {
    // display: 'flex',
    fontFamily: 'Roboto',
    fontSize: 16,
    letterSpacing: 0.15,
    paddingRight: 10,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const baseStyle = {
  textAlign: 'center',
  cursor: 'pointer',
  color: 'rgba(0,0,0,0.87)',
  borderRadius: 4,
  backgroundColor: '#E5E7F5',
  transition: 'ease-in-out',
  padding: 20,
};

const activeStyle = {
  backgroundColor: '#cacee8',
};

const acceptStyle = {
  backgroundColor: '#003FED',
  color: '#ffffff',
};

const rejectStyle = {
  backgroundColor: '#f5463d',
  color: '#ffffff',
};

export function FileInput({ ...props }) {
  const {
    name,
    classFrom,
    className,
    confirmRemoveText,
    customStyle,
    disabled,
    maxSize,
    minSize,
    multiple,
    onChange,
    previewFile,
    value,
    label,
  } = props;

  const classes = useStyles();
  const disableRemove = multiple ? true : props.disableRemove;
  const [viewOpen, setViewOpen] = useState(false);

  const onClickRemove = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (confirmRemoveText && !window.confirm(confirmRemoveText)) {
        return;
      }
      onChange([null]);
    },
    [onChange, confirmRemoveText],
  );

  const onClickPreview = React.useCallback(
    (e) => {
      e.stopPropagation();
      setViewOpen(true);
    },
    [setViewOpen],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onChange,
    maxFiles: multiple ? 0 : 1,
    multiple,
    disabled,
    maxSize,
    minSize,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(customStyle || {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Box className={clsx(className, classFrom?.[name])} width='100%'>
      <div
        {...getRootProps({
          style,
        })}
      >
        <input {...getInputProps()} />
        {!multiple && value ? (
          <div>
            <div className={classes.wrap}>
              <div className={classes.icon}>
                <FileIcon />
              </div>
              <div className={classes.text}>{value}</div>
              {!disableRemove && (
                <div>
                  <IconButton
                    onClick={onClickRemove}
                    color='primary'
                    size='large'
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
              {previewFile && (
                <div>
                  <IconButton
                    onClick={onClickPreview}
                    color='primary'
                    size='large'
                  >
                    <VisibilityIcon />
                  </IconButton>
                </div>
              )}
              {viewOpen && (
                <DocumentViewModal
                  open={viewOpen}
                  handleClose={() => setViewOpen(false)}
                  file={previewFile}
                />
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={classes.uploadIcon}>
              <CloudUploadIcon fontSize='large' />
            </div>
            {label && label}
            <div className={classes.uploadText}>
              Drag and drop or{' '}
              <span className={classes.linkText}>browse files</span>
            </div>
          </>
        )}
      </div>
    </Box>
  );
}
