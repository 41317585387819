import ReactRouterPrompt from 'react-router-prompt';
import { Button, Dialog, DialogActions, Typography } from '@mui/material';

type Props = {
  when: boolean;
  message: string;
};
export default function Prompt({ when, message }: Props) {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog maxWidth='xs' fullWidth open={isActive} onClose={onCancel}>
          <Typography padding={3}>{message}</Typography>
          <DialogActions>
            <Button variant='text' onClick={onCancel}>
              Cancel
            </Button>
            <Button variant='contained' onClick={onConfirm}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ReactRouterPrompt>
  );
}
