import makeStyles from '@mui/styles/makeStyles';
import { lighten } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '9px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.alert.info,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));
