import React, { useCallback, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import {
  CssBaseline,
  Box,
  Dialog,
  Button,
  Drawer,
  useTheme,
} from '@mui/material';
import {
  uiActions,
  authActions,
  authPersistActions,
  uiSelectors,
  authSelectors,
} from '../state';
import { useIsMediumDesktop } from '../hooks';
import {
  AnnouncementBanner,
  AnnouncementLog,
  Notification,
  PageLoader,
  QuickActionsModals,
} from '../components';
import { AppBar } from './AppBar';
import { Sidebar } from './Sidebar';
import { SidebarRight } from './SidebarRight';
import { useStyles } from './layout.styles';
import { matchPath, useLocation } from 'react-router-dom';
import { Routes } from '../lib';

/**
 * Until the new design is rolled out across the board, we have to set the background color
 * on a case-by-case basis.
 */
const routesWithNewStyling = [
  Routes.board.path,
  Routes.calendar.path,
  Routes.dashboard.path,
  Routes.adminScreeningTemplates.path,
  Routes.superAdminScreeningTemplates.path,
];

export function MainLayout({ children, ...props }) {
  const isMediumDesktop = useIsMediumDesktop();
  const dispatch = useDispatch();

  const isCaseDetailsPage = useMatch(Routes.caseDetails.path);
  const isNewCaseDetailsOn = useSelector(uiSelectors.isNewCaseDetailsOn);

  const classes = useStyles({ isCaseDetailsPage, isNewCaseDetailsOn });
  const { pathname } = useLocation();
  const hasNewStyling = routesWithNewStyling.some((r) =>
    matchPath(r, pathname),
  );
  const theme = useTheme();

  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);
  const autoIdleLogout = useSelector(authSelectors.autoIdleLogout);
  const isAnnouncementLogOpen = useSelector(uiSelectors.isAnnouncementLogOpen);

  const [remaining, setRemaining] = useState();
  const [logoutWarningOpen, setLogoutWarningOpen] = useState(false);
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (isMediumDesktop) {
      dispatch(uiActions.toggleSidebar(false));
    }
  }, [dispatch, isMediumDesktop]);

  const handleIdleLogout = useCallback(() => {
    setLogoutWarningOpen(false);
    setIsIdle(true);
  }, []);

  useEffect(() => {
    if (isIdle) {
      const { pathname, search } = window.location;
      dispatch(authActions.logout(true, encodeURIComponent(pathname + search)));
    }
  }, [isIdle, dispatch]);

  useEffect(() => {
    // if they closed the screen for longer than allowed idle time we auto log them out as well
    if (autoIdleLogout) {
      dispatch(authActions.setAutoIdleLogout(false));
      setIsIdle(true);
    }
  }, [autoIdleLogout, dispatch]);

  const handlePrompt = useCallback(() => {
    setLogoutWarningOpen(true);
  }, []);

  const { getRemainingTime, activate, getLastActiveTime } = useIdleTimer({
    crossTab: true,
    timeout: 1000 * 60 * process.env.REACT_APP_INACTIVITY_TIMEOUT,
    promptBeforeIdle: 1000 * process.env.REACT_APP_INACTIVITY_PROMPT, //time before the timeout it will prompt the user
    onIdle: handleIdleLogout,
    onPrompt: handlePrompt,
    syncTimers: 200,
    stopOnIdle: true,
  });

  useEffect(() => {
    const interval2 = setInterval(() => {
      const activeTime = getLastActiveTime();
      dispatch(authPersistActions.setLastActiveTime(activeTime));
    }, 60000);

    return () => {
      clearInterval(interval2);
    };
  }, [dispatch, getLastActiveTime]);
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleStillHere = useCallback(() => {
    activate();
    dispatch(authPersistActions.setLastActiveTime(new Date()));
    setLogoutWarningOpen(false);
  }, [dispatch, activate]);

  // memoize child components so they don't re-render with the timer
  const childrenBox = useMemo(
    () => (
      <Box
        className={classes.pageContent}
        id='pageContent'
        sx={{
          ...(hasNewStyling && {
            backgroundColor: theme.palette.background.new,
          }),
        }}
      >
        {children}
      </Box>
    ),
    [
      children,
      classes.pageContent,
      hasNewStyling,
      theme.palette.background.new,
    ],
  );

  return (
    <>
      <CssBaseline />
      <div>
        <Sidebar {...props} isSidebarOpen={isSidebarOpen} />
        <main
          className={clsx({
            [classes.content]: true,
            [classes.contentPaddingLeft]: isSidebarOpen,
          })}
        >
          <AppBar />
          <AnnouncementBanner />
          {childrenBox}
        </main>
        {isCaseDetailsPage && !isNewCaseDetailsOn && <SidebarRight />}
        <Notification />
        <PageLoader />
      </div>

      <QuickActionsModals />

      <Drawer
        anchor={'right'}
        open={isAnnouncementLogOpen}
        onClose={() => dispatch(uiActions.setAnnouncementLogOpen(false))}
      >
        <AnnouncementLog />
      </Drawer>

      <Dialog open={logoutWarningOpen}>
        <div className={classes.logoutWarning}>
          <div className={classes.logoutWarningHeader}>Are you still here?</div>
          <div className={classes.logoutWarningText}>
            Logging out in {remaining} seconds
          </div>
          <Button
            color='primary'
            variant='contained'
            className={classes.closeBtn}
            onClick={handleStillHere}
          >
            I'm still here
          </Button>
        </div>
      </Dialog>
    </>
  );
}
