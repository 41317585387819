import { styled, Box } from '@mui/material';

// TODO CG: remove interface and set alignItems on the component using alignItems=
interface FlexBetweenProps {
  align?: String;
}

export const FlexBetween = styled(Box)<FlexBetweenProps>(({ align }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: `${align}`,
}));

export const FlexCenter = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const FlexStart = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const FlexEnd = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

export const FlexColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));