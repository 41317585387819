import React from 'react';
import { useSelector } from 'react-redux';
import { listSelectors } from '../../../state';

//gets the page totals for a list, can use for displaying on another part of the pg instead of only the footer
export function PagingInfo({ resource, ...props }) {
  const listRedux = useSelector((state) =>
    listSelectors.listByResource(state, resource),
  );

  const { params: { page, rowsPerPage, numberOfRows } = {} } = listRedux;
  const offset = page * rowsPerPage;
  const pageEndRow = offset + rowsPerPage;

  return (
    <div {...props}>
      {numberOfRows > 0
        ? `${offset + 1}-${
            pageEndRow <= numberOfRows ? pageEndRow : numberOfRows
          } of ${numberOfRows}`
        : ``}
    </div>
  );
}
