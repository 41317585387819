import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { TextField, Avatar } from '@mui/material';
import { reach } from 'yup';
import { CustomCheckbox } from './CheckboxInput';
import Autocomplete from '@mui/material/Autocomplete';
import { isObject, arrayToObjByEnumOrId, DataTypes } from '../../../lib';
import { useStyles } from './inputs.styles';

/**
 *
 * @param {props} props
 * @returns
 */
export function AutocompleteInput({
  context,
  error,
  fullWidth,
  helperText,
  nestedKey,
  textFieldProps: _textFieldProps,
  autocompleteProps: _autocompleteProps,
  schema,
  avatar,
  multiple = false,
  valuePropName = 'id',
  valuePropType = DataTypes.Numeric,
  secondaryValuePropName = undefined,
  ...otherProps
}) {
  const classes = useStyles();

  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [optionsObj, setOptionsObj] = useState({});

  const { onChange, onBlur, label, autoComplete, ...textFieldProps } =
    _textFieldProps;
  const { className, style, name, options, value, ...autocompleteProps } =
    _autocompleteProps;

  useEffect(() => {
    setErrorMessage(error);
    if (error) {
      setIsDirty(true);
    }
  }, [error]);

  useEffect(() => {
    if (options.length) {
      setOptionsObj(arrayToObjByEnumOrId(options, undefined, valuePropName));
    }
  }, [options, valuePropName]);

  const getOptionSelected = useCallback(
    (option, value) => {
      if (!value) return null;
      if (isObject(value) && !multiple) {
        return option[valuePropName] === value[valuePropName];
      } else if (optionsObj[value] && !multiple) {
        return option[valuePropName] + '' === optionsObj[value].id + '';
      }
      return (
        option[valuePropName] ===
        (valuePropType === DataTypes.Numeric ? parseInt(value) ?? null : value)
      );
    },
    [optionsObj, multiple, valuePropType, valuePropName],
  );

  const getOptionLabel = useCallback(
    (option) => {
      if (isObject(option)) {
        return option.name ?? '';
      } else if (optionsObj[option]) {
        return optionsObj[option].name ?? '';
      }
      return '';
    },
    [optionsObj],
  );

  const handleChange = useCallback(
    async (e, newInputValue) => {
      const value = getValue(newInputValue, valuePropName);
      const secondaryValue = secondaryValuePropName
        ? getValue(newInputValue, secondaryValuePropName)
        : null;
      const standardEventObj = { target: { name, value, secondaryValue } };
      onChange && onChange(standardEventObj);

      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [
      name,
      onChange,
      valuePropName,
      secondaryValuePropName,
      context,
      isDirty,
      nestedKey,
      schema,
    ],
  );

  return (
    <Autocomplete
      multiple={multiple}
      key={Object.keys(optionsObj).length}
      onChange={handleChange}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      style={style}
      value={value}
      name={name}
      renderOption={(props, option, state) => {
        return (
          <div {...props} key={option[valuePropName]}>
            {multiple && (
              <CustomCheckbox
                style={{ marginRight: 8 }}
                checked={state?.selected}
              />
            )}
            {avatar && option.avatar && (
              <Avatar
                className={classes.avatar}
                sx={option.avatar.styles}
                variant={option.avatar.variant}
              >
                {option.avatar.display}
              </Avatar>
            )}
            {option.name}
          </div>
        );
      }}
      renderInput={(params) => {
        // We need this to disable browser autocomplete https://stackoverflow.com/a/58922317
        params.inputProps.autoComplete = autoComplete ?? 'off';
        return (
          <TextField
            {...params}
            data-lpignore='true'
            className={clsx({ [classes.root]: !fullWidth }, className)}
            fullWidth={fullWidth}
            label={label}
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focused,
                error: classes.error,
                disabled: classes.disabled,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.autocompleteInputRoot,
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
                input: classes.autocompleteInput,
                notchedOutline: classes.notchedOutline,
              },
            }}
            inputProps={{
              ...params.inputProps,
              'data-lpignore': true,
            }}
            error={!!errorMessage || (errorMessage !== false && !!error)}
            helperText={
              errorMessage !== false ? errorMessage || error : helperText
            }
            variant='outlined'
            {...textFieldProps}
          />
        );
      }}
      {...autocompleteProps}
    />
  );
}

/**
 * @typedef {object} props
 * @property {object} [formikProps]
 * @property {import("@mui/lab/Autocomplete").AutocompleteProps} autocompleteProps
 * @property {import("@mui/material/TextField").TextFieldProps} textFieldProps
 * @property [fullWidth] boolean
 * @property [multiple] boolean
 * @property [schema]
 * @property [error]
 * @property [valuePropName] string
 * @property [valuePropType] DataTypeType
 * @property [avatar] boolean
 */

function getValue(newInputValue, valuePropName = 'id') {
  switch (true) {
    case isObject(newInputValue):
      return newInputValue[valuePropName];
    case Array.isArray(newInputValue):
      return newInputValue.map((v) => (isObject(v) ? v[valuePropName] : v));
    default:
      return newInputValue;
  }
}
