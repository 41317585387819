import { useCallback, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { string, object, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectInput,
  TextInput,
  AutocompleteInput,
} from '../../../../components/common/inputs';
import { useGetUsersData } from '../../../../hooks';
import {
  resourceActions,
  facilitySelectors,
  systemPersistSelectors,
} from '../../../../state';
import {
  validateOnSubmit,
  convertNullFieldsToEmptyString,
  authGet,
} from '../../../../lib';
import {
  FormWithPadding,
  FormOverlay,
  FlexColumn,
} from '../../../../components';
import { UpdateScreeningSettings } from './UpdateScreeningSettings';

export function CompanyPayerForm({ open, handleClose, editId }) {
  const dispatch = useDispatch();
  const payerTypes = useSelector(systemPersistSelectors.payerTypes);
  const facilities = useSelector(facilitySelectors.facilityOptions);
  const { companyID } = useGetUsersData();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    name: '',
    longName: '',
    type: '',
    facilityIDs: [],
    authRequired: false,
    coinsuranceAllowed: false,
  });

  useEffect(() => {
    (async function () {
      if (editId) {
        const response = await authGet(
          `/companies/${companyID}/payers/${editId}`,
        );
        const { data, error } = response;
        if (error) {
          return;
        }
        const formatted = convertNullFieldsToEmptyString(data);
        setState((s) => ({ ...s, ...formatted }));
      }
    })();
  }, [dispatch, editId, companyID]);

  const onSave = useCallback(() => {
    return validateOnSubmit({ values: state, schema, setErrors })
      .then(async () => {
        setSubmitting(true);
        const { name, longName, ...rest } = state;
        const response = await dispatch(
          resourceActions.postResource({
            baseUrl: `/companies/${companyID}/payers`,
            payload: {
              name,
              longName: !!editId ? longName : name, //default the longName to name on create
              ...rest,
              id: editId,
            },
          }),
        );
        setSubmitting(false);
        const { data } = response;
        if (data) {
          handleClose(true);
        }
      })
      .catch((e) => console.error('error ', e));
  }, [dispatch, editId, handleClose, state, companyID]);

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl: `/companies/${companyID}/payers`,
        id: editId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      handleClose(true);
    }
  }, [dispatch, editId, handleClose, companyID]);

  const onChange = useCallback((name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onChangeEvent = useCallback(
    (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      onChange(name, value);
    },
    [onChange],
  );

  return (
    <FormOverlay
      handleClose={handleClose}
      open={open}
      title={`${editId ? 'Edit' : 'Add'} company payer`}
      onSave={onSave}
      isEdit={!!editId}
      handleDelete={handleDelete}
      submitting={submitting}
    >
      <FormWithPadding>
        <FlexColumn gap={5}>
          <FlexColumn gap={3}>
            <TextInput
              fullWidth
              value={state.name}
              name='name'
              onChange={onChangeEvent}
              label='Short name'
              schema={schema}
              error={errors.name}
            />
            <SelectInput
              fullWidth
              value={state.type}
              name='type'
              onChange={onChangeEvent}
              label='Payer type'
              menuItems={[...payerTypes].sort((v1, v2) =>
                v1.name.localeCompare(v2.name),
              )}
              schema={schema}
              error={errors.type}
            />
          </FlexColumn>

          <FlexColumn>
            <Typography variant='subtitle1' mb={1}>
              Applicable facilities
            </Typography>
            <Typography variant='body1' mb={2}>
              If none are selected, it will be applicable to all.
            </Typography>
            <AutocompleteInput
              fullWidth
              multiple
              textFieldProps={{
                label: 'Applicable facilities',
                onChange: onChangeEvent,
              }}
              autocompleteProps={{
                options: facilities,
                value: state.facilityIDs,
                name: 'facilityIDs',
                disableCloseOnSelect: true,
              }}
              schema={schema}
              error={errors.facilityIDs}
            />
          </FlexColumn>

          <UpdateScreeningSettings state={state} onChange={onChange} />
        </FlexColumn>
      </FormWithPadding>
    </FormOverlay>
  );
}

const schema = object().shape({
  name: string('This value must be a string').required(
    'Payer name is required',
  ),
  type: string('This value must be a string').required(
    'Payer type is required',
  ),
  facilityIDs: array(string())
    .typeError('This field must be an array')
    .nullable(),
});
