import {
  ApplicationRenewalIcon,
  ApplicationConversionIcon,
} from '../../../../components';
import {
  DomainIcon,
  HomeIcon,
  AssignmentIcon,
  AssignmentLateIcon,
} from '../../../../themes';
import {
  ApplicationStatusType,
  applicationStatusTypes,
  applicationTypes,
} from '../../../../lib';

export function getColorForApplicationStatus(
  status: ApplicationStatusTypeOrNeeded,
) {
  let color, backgroundColor;
  switch (status) {
    case applicationStatusTypes.Preparation:
      color = '#FE831A';
      backgroundColor = 'rgba(254,131,26,0.12)';
      break;
    case applicationStatusTypes.Submitted:
      color = '#0514B3';
      backgroundColor = 'rgba(5,20,179,0.12)';
      break;
    case applicationStatusTypes.AdjustmentNeeded:
      color = '#6506EE';
      backgroundColor = 'rgba(101,6,238,0.12)';
      break;
    case applicationStatusTypes.PendingApproval:
      color = '#FFBD07';
      backgroundColor = 'rgb(255,189,7,0.12)';
      break;
    case applicationStatusTypes.Approved:
      color = '#0FC076';
      backgroundColor = 'rgba(15,192,118,0.12)';
      break;
    case applicationStatusTypes.NotPursuing:
      color = '#8990B6';
      backgroundColor = 'rgba(137,144,182,0.12)';
      break;
    case applicationStatusTypes.Denied:
      color = '#F91429';
      backgroundColor = 'rgba(249,20,41,0.12)';
      break;
    case applicationStatusTypes.Appealed:
      color = '#F940B3';
      backgroundColor = 'rgba(249,64,179,0.12)';
      break;
    default:
      color = '';
      backgroundColor = '';
      break;
  }
  return { color, backgroundColor };
}
export function getIconForType(type: string, forFacility: boolean) {
  switch (type) {
    case applicationTypes.Community:
      return HomeIcon;
    case applicationTypes.NursingHome:
      return forFacility ? AssignmentIcon : DomainIcon;
    case applicationTypes.CoverageDown:
      return AssignmentLateIcon;
    case applicationTypes.Renewal:
      return ApplicationRenewalIcon; //   return (props) => CustomIcon(, props);
    case applicationTypes.Conversion:
      return ApplicationConversionIcon; //custom svg
    default:
      return AssignmentIcon;
  }
}
export const caseworkerTabs = [
  { name: 'Requests', value: 'Requests' },
  { name: 'Responses', value: 'Responses' },
];

export function getApplicationStatusOrNeeded(
  applicationStatus: ApplicationStatusType,
  applicationStatusDisplay: string,
  isApplicationNeeded: boolean,
): {
  applicationStatus: ApplicationStatusTypeOrNeeded;
  applicationStatusDisplay: string;
} {
  // ApplicationStatusEnum doesn't include a 'Needed' status
  const _applicationStatus = isApplicationNeeded ? 'Needed' : applicationStatus;
  const _applicationStatusDisplay = isApplicationNeeded
    ? 'Needed'
    : applicationStatusDisplay;

  return {
    applicationStatus: _applicationStatus,
    applicationStatusDisplay: _applicationStatusDisplay,
  };
}

type ApplicationStatusTypeOrNeeded = ApplicationStatusType | 'Needed';
