import { generatePath as generatePathRouter } from 'react-router-dom';
import { stringify } from 'query-string';

import { CompanyUserAccessTypes, NoCaseId } from './constants';
import { CaseIdType, User } from './types';

export const IS_NOT_PROD = process.env.REACT_APP_ENV !== 'production';

export function isCaseIdValid(caseId: CaseIdType) {
  return caseId !== NoCaseId;
}

export function safeIncrement(obj: Record<any, number>, key: any) {
  if (!obj[key]) {
    obj[key] = 1;
  } else {
    obj[key] += 1;
  }
}

export function generatePath(
  path: string,
  pathParams?: Record<string, any>,
  queryParams?: Record<string, any>,
) {
  const generatedPath = generatePathRouter(path, pathParams);
  const queryParamsStr = queryParams && stringify(queryParams);
  return `${generatedPath}${queryParamsStr ? `?${queryParamsStr}` : ''}`;
}

export function filterByValue<TKey extends string | number | symbol, TValue>(
  obj: Record<TKey, TValue>,
  filter: (value: TValue) => boolean,
) {
  const entries = Object.entries(obj) as [TKey, TValue][];
  return entries
    .filter(([_, value]) => filter(value))
    .reduce((acc, [key, value]) => {
      acc[key as TKey] = value;
      return acc;
    }, {} as Record<TKey, TValue>);
}

export function formatUsers(users: User[]) {
  if (users) {
    users.forEach((u) => {
      if (u.companyUserAccessType === CompanyUserAccessTypes.External) {
        u.name = u.name + ' (External User)';
      }
      if (!u.isActive) {
        u.name = u.name + ' (Inactive User)';
      }
    });
  }

  return users;
}

export function formatUsersWithAvatars({
  users,
  CompanyUserIdClaim,
  includeMe = true,
}: {
  users: User[];
  CompanyUserIdClaim: string | number;
  includeMe?: boolean;
}) {
  const isMe = (u: User) => u.id.toString() === CompanyUserIdClaim?.toString();

  const me = users.find((u) => isMe(u));

  const userList =
    users.length > 0
      ? [
          ...(me && includeMe ? [{ ...me, name: `Me (${me?.name})` }] : []),
          ...users.filter((u) => !isMe(u)),
        ].map((a) => {
          const styles = {
            color: '#ffffff',
            backgroundColor: isMe(a) ? '#0F0190' : 'primary.light',
          };

          const isExternal =
            a.companyUserAccessType === CompanyUserAccessTypes.External;
          return {
            ...a,
            avatar: {
              display: `${a.firstName?.charAt(0)?.toUpperCase() || ''}${
                a.lastName?.charAt(0)?.toUpperCase() || ''
              }`,
              styles,
              variant: isExternal ? 'rounded' : 'circular',
            },
          };
        })
      : [];

  return userList;
}

export function safeIsEmpty(array: any[] | null) {
  return (array?.length || 0) === 0;
}

export type ChangeEventType<T> = {
  target: {
    name: keyof T;
    type?: 'checkbox'; // add more types as needed
    value?: any; // TODO should not be present if type === 'checkbox'
    checked?: boolean; // TODO should only be present if type === 'checkbox'
  };
};
export type ChangeEventHandler<T> = (e: ChangeEventType<T>) => void;
export function onChangeFromEvent<T>(
  e: ChangeEventType<T>,
  onChangeFunction: (name: keyof T, value: any) => void,
) {
  const {
    target: { name, value, type, checked },
  } = e;

  const valueToUse = type === 'checkbox' ? checked : value;
  onChangeFunction(name, valueToUse);
}

export function typedEntries<K extends string, V>(obj: Record<K, V>): [K, V][] {
  return Object.entries(obj) as [K, V][];
}
