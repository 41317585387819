import { Box, styled } from '@mui/material';
import { applicationStatusTypes } from '../../lib';
import { getColorForApplicationStatus } from '../../pages/main/caseDetails/application/applicationHelpers';

type Props =
  | {
      badgeColor: string;
      badgeClassName?: never;
    }
  | {
      badgeColor?: never;
      badgeClassName: keyof typeof badgeClassNameBackgrounds;
    };
export function StatusBadge({ badgeColor, badgeClassName }: Props) {
  const backgroundColor =
    badgeColor || badgeClassNameBackgrounds[badgeClassName || ''];

  return <DotStatus component='span' sx={{ background: backgroundColor }} />;
}

const DotStatus = styled(Box)(() => ({
  zIndex: 1,
  position: 'absolute',
  fontSize: '0.75rem',
  transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  fontWeight: 500,
  lineHeight: 1,
  height: 6,
  padding: 0,
  minWidth: 6,
  borderRadius: 4,
  top: '50%',
  left: '-13px',
  transform: 'scale(1) translate(50%, -50%)',
  transformOrigin: '100% 0%',
}));

const badgeClassNameBackgrounds = {
  active: '#0FC076',
  Active: '#0FC076',
  submitted: '#0FC076',
  inactive: '#F91429',
  Inactive: '#F91429',
  NotStarted: '#F91429',
  Confirmed: '#4c34eb',
  Started: '#eb8334',
  InProgress: '#eb8334',
  Needed: '#F91429',
  Expired: '#F91429',
  Completed: '#0FC076',
  StatusCheck: '#A9A9A9',
  NewAdmission: '#A9A9A9',
  PlanNeeded: '#A9A9A9',
  LTCPayerNeeded: '#A9A9A9',
  NotNeeded: '#7620B4',
  ShortTermOnly: '#7620B4',
  Reviewing: '#A9A9A9',
  ReadyToSubmit: '#A9A9A9',
  LTCPayerReceived: '#0000FF',
  CaseworkerRequest: '#0000FF',
  ResponseSubmitted: '#0000FF',
  CaseApproved: '#0C9A5E',
  MedicaidApproved: '#0C9A5E',
  IneligibleForMedicaid: '#F91429',
  Pending: '#B9BDD4',
  New: '#FE831A',
  InReview: '#EE068F',
  Rejected: '#F91429',

  // application statuses:
  Preparation: getColorForApplicationStatus(applicationStatusTypes.Preparation)
    .color,
  Submitted: getColorForApplicationStatus(applicationStatusTypes.Submitted)
    .color,
  AdjustmentNeeded: getColorForApplicationStatus(
    applicationStatusTypes.AdjustmentNeeded,
  ).color,
  PendingApproval: getColorForApplicationStatus(
    applicationStatusTypes.PendingApproval,
  ).color,
  NotPursuing: getColorForApplicationStatus(applicationStatusTypes.NotPursuing)
    .color,
  Appealed: getColorForApplicationStatus(applicationStatusTypes.Appealed).color,
  //these below may be used for other types of statuses as well
  Approved: getColorForApplicationStatus(applicationStatusTypes.Approved).color,
  Denied: getColorForApplicationStatus(applicationStatusTypes.Denied).color,

  Draft: '#B9BDD4',
  Published: '#096EF8',
  CurrentlyShowing: '#0FC076',

  '': '',
} as const;
