import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, BoxProps, IconButton, Tooltip } from '@mui/material';
import {
  AssignmentIndOutlinedIcon,
  AddTaskOutlinedIcon,
  StickyNoteOutlinedIcon,
  PrintOutlinedIcon,
} from '../../../themes';
import { uiActions } from '../../../state';
import { IconType, QuickActionType, Resident } from '../../../lib';

type Props = {
  orientation?: 'vertical' | 'horizontal';
  containerProps?: BoxProps;
  iconBoxProps?: BoxProps;
  iconStyles?: (arg0: QuickActionTypeType) => Record<any, any>;
  size?: 'small' | 'medium' | 'large';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  resident: Resident;
};
export const QuickActionButtons = memo(function ({
  containerProps = {},
  iconBoxProps = {},
  iconStyles = () => ({}),
  size = 'medium',
  onClick,
  resident,
  orientation = 'horizontal',
}: Props) {
  const dispatch = useDispatch();

  const quickActionsOnClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      type: QuickActionTypeType,
    ) => {
      if (onClick) {
        onClick(event);
      }

      dispatch(uiActions.setQuickActionCaseId(resident.caseID));
      dispatch(uiActions.setQuickActionResidentId(resident.residentID));

      const fullName = `${resident.firstName} ${
        resident.middleName ? `${resident.middleName} ` : ''
      }${resident.lastName}`;
      dispatch(uiActions.setQuickActionCaseName(fullName));

      dispatch(uiActions.setQuickActionType(type));
    },
    [dispatch, onClick, resident],
  );

  const QuickActionButton = ({
    actionType,
    title,
    Icon,
  }: {
    actionType: QuickActionTypeType;
    title: string;
    Icon: IconType;
  }) => (
    <Tooltip
      title={title}
      placement={orientation === 'horizontal' ? 'top' : 'left'}
    >
      <Box {...iconBoxProps}>
        <IconButton
          size={size}
          onClick={(e) => quickActionsOnClick(e, actionType)}
          sx={{ ...iconStyles(actionType) }}
        >
          <Icon fontSize={size} />
        </IconButton>
      </Box>
    </Tooltip>
  );

  return (
    <Box
      {...containerProps}
      display={orientation === 'horizontal' ? 'flex' : 'block'}
    >
      <QuickActionButton
        actionType={QuickActionType.Tasks}
        title='Create task'
        Icon={AddTaskOutlinedIcon}
      />
      <QuickActionButton
        actionType={QuickActionType.Application}
        title='Add application'
        Icon={AssignmentIndOutlinedIcon}
      />
      <QuickActionButton
        actionType={QuickActionType.Notes}
        title='Add note'
        Icon={StickyNoteOutlinedIcon}
      />
      <QuickActionButton
        actionType={QuickActionType.Print}
        title='Print document'
        Icon={PrintOutlinedIcon}
      />
    </Box>
  );
});

type QuickActionTypeType =
  (typeof QuickActionType)[keyof typeof QuickActionType];
