import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { AppsIcon } from '../themes';
import {
  FlexColumn,
  NoPrint,
  Overlay,
  QuickActionButtons,
} from '../components';
import { residentSelectors, uiActions, uiSelectors } from '../state';
import { useStyles } from './sidebarRight.styles';
import { QuickActionType, Routes } from '../lib';

export const SidebarRight = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isCaseDetailsPage = useMatch(Routes.caseDetails.path);
  const isOverlayOpen = useSelector(uiSelectors.isOverlayOpen);
  const resident = useSelector(residentSelectors.resident);

  return (
    <NoPrint>
      <Drawer
        variant='permanent'
        anchor='right'
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div>
          {isCaseDetailsPage && (
            <AppsIcon
              className={classes.toggleOverlay}
              onClick={() => dispatch(uiActions.setOverlayOpen(!isOverlayOpen))}
              id='overlayButton'
            />
          )}
        </div>
        <FlexColumn alignItems='center'>
          {isCaseDetailsPage && (
            <QuickActionButtons
              orientation='vertical'
              size='small'
              iconBoxProps={{ paddingY: 1 }}
              iconStyles={(actionType) => ({
                color: ActionTypeColors[actionType],
              })}
              resident={resident}
            />
          )}
        </FlexColumn>
      </Drawer>
      {isCaseDetailsPage && isOverlayOpen && <Overlay />}
    </NoPrint>
  );
});

const ActionTypeColors = {
  [QuickActionType.Application]: 'success.main',
  [QuickActionType.Notes]: 'info.main',
  [QuickActionType.Print]: 'pink.main',
  [QuickActionType.Tasks]: 'primary.main',
  [QuickActionType.None]: '',
};
