import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: [],
  hasNoAllowedCompanies: false,
  company: {
    payers: [],
    residentInternalIntegrationSettings: {},
    allInternalIntegrationSettings: [],
    regions: [],
    dashboardUrl: null,
  },
};

export const companies = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies(state, action) {
      state.companies = action.payload;
    },
    setHasNoAllowedCompanies(state, action) {
      state.hasNoAllowedCompanies = action.payload;
    },
    setCompany(state, action) {
      state.company = { ...(action.payload || {}) };
    },
    setCompanyPayers(state, action) {
      state.company = { ...state.company, ...action.payload };
    },
    setResidentInternalIntegrationSettings(state, action) {
      state.company = {
        ...state.company,
        residentInternalIntegrationSettings: action.payload,
      };
    },
    setAllInternalIntegrationSettings(state, action) {
      state.company = {
        ...state.company,
        allInternalIntegrationSettings: action.payload,
      };
    },
    setRegions(state, action) {
      state.company = { ...state.company, regions: action.payload };
    },
    setCompanyDashboardUrl(state, action) {
      state.company = {
        ...state.company,
        dashboardUrl: action.payload,
      };
    },
  },
});
