import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import {
  Toolbar,
  Tooltip,
  Typography,
  Button,
  TableRow,
  TableCell,
  useTheme,
} from '@mui/material';
import {
  List,
  DataGrid,
  TextField,
  ListFiltersActions,
  BooleanField,
  FlexCenter,
} from '../../../components';
import { CompanyPayerForm } from '../settings/payer/CompanyPayerForm';
import { CompanyPayerUpdateScreeningSettingsForm } from './payer/CompanyPayerUpdateScreeningSettingsForm';
import { AddIcon, InfoOutlinedIcon } from '../../../themes';
import { useStyles } from './companyPayersPage.styles';
import {
  useGetQueryParams,
  useGetUsersData,
  useGlobalRefresh,
} from '../../../hooks';
import {
  facilityActions,
  systemPersistSelectors,
  listSelectors,
  listActions,
} from '../../../state';
import { getFiltersFromDropdownList } from '../../../lib';

export function CompanyPayersPage({ facilityId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const refresh = useGlobalRefresh();

  const [openAddPayer, setOpenAddPayer] = useState(false);
  const [openEditPayer, setOpenEditPayer] = useState(false);
  const [openBulkUpdatePayer, setOpenBulkUpdatePayer] = useState(false);
  const [editId, setEditId] = useStateWithCallbackInstant(null, (payerId) =>
    setOpenEditPayer(!!payerId),
  );

  const { search, 'payer-type': payerTypes } = useGetQueryParams();
  const { companyID } = useGetUsersData();

  const listResource = 'companyPayers';
  const listRedux = useSelector((state) =>
    listSelectors.listByResource(state, listResource),
  );
  const selectedIds = listRedux?.params?.selectedIds;
  // TODO needed for https://bitbean.atlassian.net/browse/ADS-2642
  // const arePayersSelected = selectedIds?.length;

  const cellBorderLeft = {
    borderLeft: `1px solid ${theme.palette.divider}`,
  };

  useEffect(() => {
    dispatch(facilityActions.getFacilities());
  }, [dispatch]);

  const clearSelectedIds = useCallback(() => {
    dispatch(
      listActions.updateListParams({
        list: listResource,
        params: { selectedIds: undefined },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    return () => clearSelectedIds();
  }, [clearSelectedIds]);

  const UpdateScreeningButton = () => {
    return (
      <FlexCenter>
        <Button
          sx={{ color: 'primary.main' }}
          disabled={true}
          // TODO enable appropriately when ready: https://bitbean.atlassian.net/browse/ADS-2642
          // disabled={!arePayersSelected}
          onClick={() => setOpenBulkUpdatePayer(true)}
        >
          Update screening settings
        </Button>
        {/* TODO remove info icon + tooltip when ready: https://bitbean.atlassian.net/browse/ADS-2642 */}
        <Tooltip
          color='text.secondary'
          arrow
          title='Coming soon! These settings will determine which fields are available on screenings when this payer is active.'
        >
          <InfoOutlinedIcon fontSize='small' />
        </Tooltip>
      </FlexCenter>
    );
  };

  const MetaHeader = () => {
    return (
      <TableRow sx={{ backgroundColor: '#EEF1F7' }}>
        <TableCell />
        <TableCell />
        <TableCell sx={cellBorderLeft}>
          <Typography variant='subtitle2' color='text.secondary'>
            General settings
          </Typography>
        </TableCell>
        <TableCell />
        <TableCell sx={cellBorderLeft}>
          <Typography
            display='flex'
            alignItems='center'
            variant='subtitle2'
            color='text.secondary'
          >
            Screening settings
            <Tooltip
              color='text.secondary'
              arrow
              sx={{ ml: 1 }}
              title='Coming soon! These settings will determine which fields are available on screenings when this payer is active.'
              // TODO https://bitbean.atlassian.net/browse/ADS-2642
              // When ready, the tooltip title should be:
              // title={
              //   <>
              //     These settings determine which fields are available on
              //     screenings when this payer is active. Field visibility can be
              //     configured further{' '}
              //     <Typography
              //       color='#FFF'
              //       textDecorationColor='#FFF'
              //       component={Link} // this is a react-router-dom Link
              //       to={generatePath(
              //         Routes.settings.path,
              //         {},
              //         { view: SettingsPageViews.screenings },
              //       )}
              //     >
              //       here
              //     </Typography>
              //     .
              //   </>
              // }
            >
              <InfoOutlinedIcon fontSize='small' />
            </Tooltip>
          </Typography>
        </TableCell>
        <TableCell />
      </TableRow>
    );
  };

  return (
    <>
      <List
        baseUrl={`/companies/${companyID}/payers`}
        resource={listResource}
        filter={{ text: search, payerTypes }}
        actions={<ListActions setOpenAddPayer={setOpenAddPayer} />}
        bulkActionButtons={<UpdateScreeningButton />}
        rowClick={(_, row) => setEditId(row.id)}
        hideShadow
        selectedItemName='payers'
        orderBy='name'
        order='asc'
      >
        <DataGrid metaHeader={<MetaHeader />}>
          <TextField source='name' label='Name' />
          {/* General settings */}
          <TextField
            source='payerTypeDisplay'
            label='Type'
            sortBy='type'
            cellStyle={cellBorderLeft}
            headerClassName={classes.cellBorderLeft}
          />
          <TextField
            source='facilitiesDisplay'
            label='Facilities'
            sortable={false}
          />
          {/* Screening settings */}
          <BooleanField
            label='Auth'
            sortable={false}
            className={classes.booleanIcon}
            source='authRequired'
            hideFalse
            cellStyle={cellBorderLeft}
            headerClassName={classes.cellBorderLeft}
          />
          <BooleanField
            label='Coinsurance'
            sortable={false}
            className={classes.booleanIcon}
            source='coinsuranceAllowed'
            hideFalse
          />
        </DataGrid>
      </List>
      {openAddPayer && (
        <CompanyPayerForm
          facilityId={facilityId}
          open
          handleClose={() => setOpenAddPayer(false)}
        />
      )}
      {openEditPayer && (
        <CompanyPayerForm
          open
          handleClose={(doRefresh = false) => {
            setEditId(null);
            if (doRefresh) {
              refresh();
            }
          }}
          facilityId={facilityId}
          editId={editId}
        />
      )}
      {openBulkUpdatePayer && (
        <CompanyPayerUpdateScreeningSettingsForm
          handleClose={(doRefresh = false) => {
            setOpenBulkUpdatePayer(false);
            clearSelectedIds();
            if (doRefresh) {
              refresh();
            }
          }}
          selectedIds={selectedIds}
        />
      )}
    </>
  );
}

function ListActions({ setOpenAddPayer }) {
  const classes = useStyles();
  const payerTypes = useSelector(systemPersistSelectors.payerTypes);
  const [payerTypesFilter, setPayerTypesFilter] = useState('');

  useEffect(() => {
    setPayerTypesFilter(getFiltersFromDropdownList(payerTypes));
  }, [payerTypes]);

  return (
    <>
      <Toolbar className={classes.actions} disableGutters>
        <div className={classes.listHeader}>
          <Typography className={classes.title} component='div'>
            Payers
          </Typography>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => setOpenAddPayer(true)}
          >
            Payer
          </Button>
        </div>
      </Toolbar>

      <ListFiltersActions
        className={classes.listFiltersActions}
        hasSearchField
        hideToggleShowBtn
        searchAndFilterInline
        filtersProps={{
          hasMultipleFilters: true,
          filters: [
            {
              filter: payerTypesFilter,
              queryParam: 'payer-type',
              label: 'Payer type',
            },
          ],
        }}
      />
    </>
  );
}
