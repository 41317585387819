import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Tooltip } from '@mui/material';
import { ManageAccountsIcon, ChevronRightIcon } from '../../themes';
import { IS_NOT_PROD, Routes } from '../../lib';

export default function SuperAdminMenu({ handleClose, classes }) {
  const [open, setOpen] = useState(false);
  const handleCloseSuperAdminMenu = useCallback(() => {
    setOpen(false);
    handleClose();
  }, [handleClose]);
  return (
    <Tooltip
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disableFocusListener //enable clicks within the tooltip if it was opened via a click vs hover see https://github.com/mui/material-ui/issues/30973
      placement={'right'}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#fff',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            color: '#000',
            px: 0,
            py: 1,
          },
        },
      }}
      title={
        <>
          <MenuItem
            component={Link}
            to={Routes.companies.path}
            onClick={handleCloseSuperAdminMenu}
          >
            Companies
          </MenuItem>
          <MenuItem
            component={Link}
            to={Routes.createCompany.path}
            onClick={handleCloseSuperAdminMenu}
          >
            Create Company
          </MenuItem>
          {IS_NOT_PROD && (
            <MenuItem
              component={Link}
              to={Routes.superAdminScreeningSettings.path}
              onClick={handleCloseSuperAdminMenu}
            >
              Default screenings
            </MenuItem>
          )}
          <MenuItem
            component={Link}
            to={Routes.facilitiesSuperAdmin.path}
            onClick={handleCloseSuperAdminMenu}
          >
            Facilities
          </MenuItem>
          <MenuItem
            component={Link}
            to={Routes.superAdminUsersPage.path}
            onClick={handleCloseSuperAdminMenu}
          >
            Users
          </MenuItem>
          <MenuItem
            component={Link}
            to={Routes.announcements.path}
            onClick={handleCloseSuperAdminMenu}
          >
            Announcements
          </MenuItem>
        </>
      }
    >
      <MenuItem className={classes.menuItem}>
        <ManageAccountsIcon />
        SuperAdmin
        <ChevronRightIcon sx={{ ml: 1, mr: -2 }} />
      </MenuItem>
    </Tooltip>
  );
}
