export interface ScreeningTemplateBasic {
  id: number;
  parentScreeningTemplateID?: number;
  parentScreeningTemplateName?: string;
  name: string;
  status: ScreeningTemplateStatusType;
}

export interface ScreeningTemplate extends ScreeningTemplateBasic {
  screeningSettings: ScreeningSettings;
}

export interface ScreeningTemplateHierarchy {
  parent: ScreeningTemplateBasic;
  children: ScreeningTemplateBasic[];
}

export interface ScreeningSettings {
  triggerDaysAfterAdmission: number;
  noDueDate: boolean;
  dueDaysAfterAdmission: number | null;
  requireApproval: boolean;
  approverUserTypes: ScreeningApproverRoleType[] | null;
  locationScope: LocationScopeType;
  regionIDs: number[] | null;
  stateIDs: number[] | null;
  facilityIDs: number[] | null;

  triggerDaysAfterAdmissionParentValue: number;
  noDueDateParentValue: boolean;
  dueDaysAfterAdmissionParentValue: number | null;
  requireApprovalParentValue: boolean;
  approverUserTypesParentValue: ScreeningApproverRoleType[] | null;
  locationScopeParentValue: LocationScopeType;
  regionIDsParentValue: number[] | null;
  stateIDsParentValue: number[] | null;
  facilityIDsParentValue: number[] | null;
}

export interface ScreeningFieldSettings {
  fieldCategories: Record<FieldCategoryType, FieldCategoryDetails>;
}

export interface FieldCategoryDetails {
  categorySettings: CategorySetting;
  fieldSettings: FieldSetting[];
}

interface CategorySetting {
  categoryDisplay: string;
  requireConfirmation: boolean;
  requireConfirmationParentValue: boolean;
}

export interface FieldSetting {
  friendlyName: string;
  fullyQualifiedName: string;
  infoText: string | null;
  fixedVisibility: FieldVisibilityType | null;
  visibility: FieldVisibilityType;
  visibilityParentValue: FieldVisibilityType | null;
  conditions: FieldCondition[] | null;
  conditionsParentValue: FieldCondition[] | null;
}

export interface FieldCondition {
  condition: ConditionType;
  visibility: FieldVisibilityLimitedType;
}

export interface ScreeningListParams {
  screeningType?: ScreeningTypeType;
}

export const ScreeningTypes = {
  Resident: 'Resident',
} as const;
export type ScreeningTypeType =
  (typeof ScreeningTypes)[keyof typeof ScreeningTypes];

export const ScreeningTemplateStatus = {
  active: 'Active',
  inactive: 'Inactive',
  draft: 'Draft',
} as const;
export type ScreeningTemplateStatusType =
  (typeof ScreeningTemplateStatus)[keyof typeof ScreeningTemplateStatus];

const ScreeningApproverRole = {
  Tracker: 'Tracker',
  Regional: 'Regional user',
} as const;
type ScreeningApproverRoleType = keyof typeof ScreeningApproverRole;

export const LocationScope = {
  none: 'None',
  region: 'Region',
  state: 'State',
  facility: 'Facility',
} as const;
export type LocationScopeType =
  (typeof LocationScope)[keyof typeof LocationScope];

const FieldCategory = {
  residentProfile: 'residentProfile',
  spouseDetails: 'spouseDetails',
  contacts: 'contacts',
} as const;
export type FieldCategoryType =
  (typeof FieldCategory)[keyof typeof FieldCategory];

export const FieldVisibility = {
  Optional: 'Optional',
  Required: 'Required',
  Hidden: 'Hidden',
  Conditional: 'Conditional',
} as const;
export type FieldVisibilityType =
  (typeof FieldVisibility)[keyof typeof FieldVisibility];
export type FieldVisibilityLimitedType = Exclude<
  FieldVisibilityType,
  'Conditional'
>;

export const Condition = {
  None: { id: 'None', name: 'None' },
  ResidentPlanIsLongTerm: {
    id: 'ResidentPlanIsLongTerm',
    name: 'Resident plan is long term',
  },
  ResidentPlanIsShortTerm: {
    id: 'ResidentPlanIsShortTerm',
    name: 'Resident plan is short term',
  },
  ResidentPlanNeeded: {
    id: 'ResidentPlanNeeded',
    name: 'Resident plan needed',
  },
} as const;
type ConditionType = (typeof Condition)[keyof typeof Condition]['id'];
