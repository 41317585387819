import { useState, useCallback } from 'react';
import { Box, Typography, Checkbox, Button } from '@mui/material';
import {
  FlexCenter,
  BooleanToggleButton,
  FlexColumn,
  FlexBetween,
} from '../../../../components';
import { ChevronRightIcon, ExpandLessIcon } from '../../../../themes';
import { pluralize } from '../../../../lib';

interface UpdateScreeningSettingsProps {
  state: any;
  onChange: (name: string, value: any) => void;
  selectedIds?: number[];
  payerNames?: string | never;
}
export const UpdateScreeningSettings = (
  props: UpdateScreeningSettingsProps,
) => {
  const { state, onChange, selectedIds, payerNames } = props;

  const [showPayers, setShowPayers] = useState(false);
  const [doUpdateAuthRequired, setDoUpdateAuthRequired] = useState(false);
  const [doUpdateCoinsuranceAllowed, setDoUpdateCoinsuranceAllowed] =
    useState(false);

  const isBulkUpdate = !!selectedIds?.length;

  const renderToggle = useCallback(
    (
      label: string,
      name: string,
      doUpdate: boolean,
      setDoUpdate: (arg0: boolean) => void,
    ) => {
      const Container = isBulkUpdate ? Box : FlexBetween;
      return (
        <Container>
          <FlexCenter>
            {isBulkUpdate ? (
              <Checkbox
                onChange={(e) => {
                  setDoUpdate(e.target.checked);
                  if (!e.target.checked) {
                    onChange(name, null);
                  }
                }}
              />
            ) : null}
            <Typography variant='subtitle2'>{label}</Typography>
          </FlexCenter>
          <Box marginLeft={isBulkUpdate ? 5 : 0}>
            <BooleanToggleButton
              name={name}
              value={state[name]}
              onChange={onChange}
              disabled={isBulkUpdate && !doUpdate}
            />
          </Box>
        </Container>
      );
    },
    [isBulkUpdate, onChange, state],
  );

  return (
    <FlexColumn>
      <Typography variant='subtitle1'>Screening settings</Typography>

      <Typography variant='body1' paddingBottom={1}>
        Coming soon! These settings will determine which fields are available on
        screenings when this payer is active.
      </Typography>

      {/* TODO https://bitbean.atlassian.net/browse/ADS-2642 - when ready, the text should be: */}
      {/* <Typography variant='body1' paddingBottom={1}>
        These settings determine which fields are available on screenings when
        this payer is active. Field visibility can be configured further{' '}
        <Typography
          variant='subtitle2'
          sx={{ textDecoration: 'none' }}
          color='primary.main'
          component={Link} // this is a react-router-dom Link
          to={generatePath(
            Routes.settings.path,
            {},
            { view: SettingsPageViews.screenings },
          )}
        >
          here
        </Typography>
        .
      </Typography> */}

      {isBulkUpdate && (
        <Box mt={5} mb={1}>
          <Typography variant='subtitle1'>
            {`Update ${selectedIds.length} ${pluralize(
              'payer',
              selectedIds.length,
            )}`}
          </Typography>
          <Typography variant='body1'>
            Check off the field(s) you’d like to update and adjust the value(s).
            If you don’t check off a field, the value will not be changed.
          </Typography>
          <FlexCenter mt={2} onClick={() => setShowPayers(!showPayers)}>
            <Button
              size='small'
              sx={{ color: 'primary.main' }}
              endIcon={showPayers ? <ExpandLessIcon /> : <ChevronRightIcon />}
            >
              View payers
            </Button>
          </FlexCenter>
          {showPayers && (
            <Box
              mt={1}
              maxHeight='250px'
              maxWidth='100%'
              sx={{ overflowY: 'scroll' }}
            >
              {payerNames}
            </Box>
          )}
        </Box>
      )}

      <FlexColumn gap={3} mt={2}>
        {renderToggle(
          'Auth required',
          'authRequired',
          doUpdateAuthRequired,
          setDoUpdateAuthRequired,
        )}
        {renderToggle(
          'Coinsurance allowed',
          'coinsuranceAllowed',
          doUpdateCoinsuranceAllowed,
          setDoUpdateCoinsuranceAllowed,
        )}
      </FlexColumn>
    </FlexColumn>
  );
};
