import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { hasAuthRequestToken } from '../../lib';

import {
  CreateCompany,
  ForgotPassword,
  GetToken,
  LoginPage,
  Logout,
  NotFoundPage,
  SetPassword,
  VerifyLoginPage,
} from '../../pages';

//lazy loading for route-based code splitting to reduce bundle size.
const AlertsPage = lazy(() => import('../../pages/main/AlertsPage'));
const AllUsers = lazy(() => import('../../pages/superAdmin/AllUsers'));
const Announcements = lazy(() =>
  import('../../pages/superAdmin/Announcements'),
);
const ApplicationsPage = lazy(() =>
  import('../../pages/main/ApplicationsPage'),
);
const CalendarPage = lazy(() => import('../../pages/main/CalendarPage'));
const CaseDetails = lazy(() =>
  import('../../pages/main/caseDetails/CaseDetails'),
);
const Companies = lazy(() => import('../../pages/superAdmin/Companies'));
const CompanyContactsPage = lazy(() =>
  import('../../pages/main/CompanyContactsPage'),
);
const CompanyDashboards = lazy(() =>
  import('../../pages/main/CompanyDashboards'),
);
const DashboardPage = lazy(() => import('../../pages/main/DashboardPage'));
const FacilitiesDashboardPage = lazy(() =>
  import('../../pages/main/FacilitiesDashboardPage'),
);
const FacilitiesSuperAdmin = lazy(() =>
  import('../../pages/superAdmin/Facilities'),
);
const FacilitiesPage = lazy(() => import('../../pages/main/FacilitiesPage'));
const FacilityDetails = lazy(() =>
  import('../../pages/main/facilityDetails/FacilityDetails'),
);
const Intake = lazy(() => import('../../pages/intake/Intake'));
const MappingPage = lazy(() => import('../../pages/main/MappingPage'));
const RegionsPage = lazy(() => import('../../pages/admin/RegionsPage'));
const ResidentsBoard = lazy(() => import('../../pages/main/ResidentsBoard'));
const ResidentsPage = lazy(() => import('../../pages/main/ResidentsPage'));
const Settings = lazy(() => import('../../pages/account/settings/Settings'));
const ScreeningsPage = lazy(() =>
  import('../../pages/account/settings/ScreeningsPage'),
);
const ScreeningTemplates = lazy(() =>
  import('../../pages/account/settings/screenings/ScreeningTemplates'),
);
const UserProfilePage = lazy(() =>
  import('../../pages/account/UserProfilePage'),
);
const NotificationPreferences = lazy(() =>
  import('../../pages/account/NotificationPreferences'),
);
const UsersPage = lazy(() => import('../../pages/account/UsersPage'));
// end of lazy loading routes

export const PublicRoutes = {
  login: {
    path: '/account/login',
    element: hasAuthRequestToken() ? <Navigate to='/' /> : <LoginPage />,
  },
  verifyLogin: {
    path: '/account/verify-login',
    element: <VerifyLoginPage />,
  },
  forgotPassword: {
    path: '/account/password/forgot-password',
    element: <ForgotPassword />,
  },
  getToken: { path: '/ext/get-token', element: <GetToken /> },
  logout: { path: '/ext/logout', element: <Logout /> },
  setPassword: { path: '/account/password/reset', element: <SetPassword /> },
  home: { path: '/', element: <Navigate to='/dashboard' /> },
  notFound: { path: '/not-found', element: <NotFoundPage /> },
  catchAll: { path: '*', element: <NotFoundPage /> },
};

export const PrivateRoutes = {
  userProfile: {
    path: '/account/profile',
    element: <UserProfilePage />,
  },
  notificationPreferences: {
    path: '/account/notifications',
    element: <NotificationPreferences />,
  },
  dashboard: {
    path: '/dashboard',
    element: <DashboardPage />,
  },
  calendar: {
    path: '/calendar',
    element: <CalendarPage />,
  },
  alertsPage: {
    path: '/alerts',
    element: <AlertsPage />,
  },
  residentsList: {
    path: '/residents',
    element: <ResidentsPage />,
  },
  applications: {
    path: '/applications',
    element: <ApplicationsPage />,
  },
  board: {
    path: '/board',
    element: <ResidentsBoard />,
  },
  caseDetails: {
    path: '/cases/:id',
    element: <CaseDetails />,
  },
  intake: {
    path: '/residents/intake',
    element: <Intake />,
  },
  facilities: {
    path: '/facilities',
    element: <FacilitiesPage />,
  },
  facilityDetails: {
    path: '/facilities/:id',
    element: <FacilityDetails />,
  },
  mappingPage: {
    path: '/mapping',
    element: <MappingPage />,
  },
  caseWorkers: {
    path: '/case-workers',
    element: <CompanyContactsPage />,
  },
  facilitiesDashboard: {
    path: '/facilities-dashboard',
    element: <FacilitiesDashboardPage />,
  },
  settings: {
    path: '/settings',
    element: <Settings />,
  },
  adminScreeningTemplates: {
    path: '/admin/account/screeningTemplates/:screeningType',
    element: <ScreeningTemplates />,
  },
  adminUsersPage: {
    path: '/admin/account/users',
    element: <UsersPage />,
  },
  regions: {
    path: '/admin/account/regions',
    element: <RegionsPage />,
  },
  superAdminUsersPage: {
    path: '/superAdmin/users',
    element: <AllUsers />,
  },
  createCompany: {
    path: '/superAdmin/create-company',
    element: <CreateCompany />,
  },
  companies: {
    path: '/superAdmin/companies',
    element: <Companies />,
  },
  announcements: {
    path: '/superAdmin/announcements',
    element: <Announcements />,
  },
  facilitiesSuperAdmin: {
    path: '/superAdmin/facilities',
    element: <FacilitiesSuperAdmin />,
  },
  superAdminScreeningSettings: {
    path: '/superAdmin/screenings',
    element: <ScreeningsPage />,
  },
  superAdminScreeningTemplates: {
    path: '/superAdmin/screeningTemplates/:screeningType',
    element: <ScreeningTemplates />,
  },
  companyDashboards: {
    path: '/companyDashboards',
    element: <CompanyDashboards />,
  },
};

export const Routes = {
  ...PublicRoutes,
  ...PrivateRoutes,
};
