import { useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import { CustomTooltip } from '../components';
import { ExpandLessIcon, ExpandMoreIcon } from '../themes';

export function useExpandCollapse(defaultState = false) {
  const [isExpanded, setIsExpanded] = useState(defaultState);

  const expandCollapse = useMemo(
    () => (
      <CustomTooltip title={isExpanded ? 'Collapse' : 'Expand'} placement='top'>
        <IconButton onClick={() => setIsExpanded((prev) => !prev)}>
          {isExpanded ? (
            <ExpandLessIcon color='action' />
          ) : (
            <ExpandMoreIcon color='action' />
          )}
        </IconButton>
      </CustomTooltip>
    ),
    [isExpanded],
  );

  return { expandCollapse, isExpanded };
}
