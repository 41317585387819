import { useCallback, Fragment, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid } from '@mui/material';
import { AddressSearchInput } from './AddressSearch';
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { systemPersistSelectors } from '../../../state';
import { FormGrid, FormTextButton } from '../../../components';

export function AddressFields({
  disabled,
  schema,
  errors,
  values,
  addressFieldName = 'address',
  firstAddressFieldName = 'address1',
  firstAddressLabel,
  onChangeField,
  companyType,
  property = '',
  onChange,
  nestedKey,
  viewVersion,
}) {
  const [manualOpen, setManualOpen] = useState(false);
  const states = useSelector(systemPersistSelectors.states, shallowEqual);

  const handleAddressSearch = useCallback(
    (value = {}) => {
      if (value[firstAddressFieldName] !== '') {
        setManualOpen(true);
      }

      return onChange(value);
    },
    [onChange, firstAddressFieldName, setManualOpen],
  );

  const onChangeStateID = useCallback(
    (e) => {
      const { name, value } = e.target;
      const stateShortName = states.find((s) => s.id === value).shortName;
      handleAddressSearch({ [name]: value, stateShortName });
    },
    [handleAddressSearch, states],
  );

  const initialAddressString = getAddressString(values || {});

  return (
    <FormGrid container spacing={3}>
      <Grid item xs={12}>
        <AddressSearchInput
          viewVersion={viewVersion}
          fullWidth
          parentHandleSelect={handleAddressSearch}
          label={
            firstAddressLabel ||
            (property === 'mailing' ? 'Enter Address' : 'Enter Home address')
          }
          style={{ maxWidth: '100%', flexGrow: 1 }}
          initialAddress={initialAddressString}
          error={!!errors[addressFieldName] || !!errors[firstAddressFieldName]}
          disabled={disabled}
        />
      </Grid>
      {manualOpen && (
        <Fragment>
          <Grid item xs={6}>
            <TextInput
              value={values[firstAddressFieldName] || ''}
              name={firstAddressFieldName}
              onChange={onChangeField}
              label='Street Address'
              schema={schema}
              error={errors[firstAddressFieldName]}
              nestedKey={nestedKey && `${nestedKey}.${firstAddressFieldName}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              value={values.city || ''}
              name={'city'}
              onChange={onChangeField}
              label='City'
              schema={schema}
              error={errors.city}
              fullWidth
              nestedKey={nestedKey && `${nestedKey}.${'city'}`}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              fullWidth
              style={{ flexGrow: 1 }}
              value={values.stateID || ''}
              name={'stateID'}
              onChange={onChangeStateID}
              label='State'
              menuItems={states}
              schema={schema}
              context={{ companyType }}
              error={errors.stateID}
              nestedKey={nestedKey && `${nestedKey}.${'stateID'}`}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  style={{ flexGrow: 1 }}
                  value={values.zip || ''}
                  name={'zip'}
                  onChange={onChangeField}
                  label='Zip'
                  schema={schema}
                  error={errors.zip}
                  nestedKey={nestedKey && `${nestedKey}.${'zip'}`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  style={{ flexGrow: 1 }}
                  value={values.county || ''}
                  name={'county'}
                  onChange={onChangeField}
                  label='County'
                  schema={schema}
                  error={errors.county}
                  nestedKey={nestedKey && `${nestedKey}.${'county'}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )}
      <FormTextButton variant='text' onClick={() => setManualOpen(!manualOpen)}>
        {disabled ? '' : manualOpen ? 'Close manual address' : 'Manual address'}
      </FormTextButton>
    </FormGrid>
  );
}

function getAddressString({ address1, city, stateShortName, zip }) {
  const arr = [
    address1?.trim(),
    city?.trim(),
    `${stateShortName || ''} ${zip || ''}`.trim(),
  ];
  return arr.filter((i) => i).join(', ');
}
