import { authGet, authPost } from '../../lib';
import { uiActions } from '..';
import { residents } from './state';
import { Dispatch, Payload } from '../types';

const { actions } = residents;

export const residentActions = {
  ...actions,
  submitLongTermPayer(payload: Payload, residentId: number, isSpouse: boolean) {
    return async (dispatch: Dispatch) => {
      const response = await authPost(
        `/residents/${residentId}/long-term-payer`,
        payload,
      );
      const { data, error } = response;
      if (!error) {
        if (isSpouse) {
          dispatch(actions.setSpouseLongTermPayer(data));
        } else {
          dispatch(actions.setResidentLongTermPayer(data));
        }
      }

      return response;
    };
  },

  getResidentCoverageOptions(residentId: number) {
    return async (dispatch: Dispatch) => {
      const response = await authGet(
        `/residents/${residentId}/coverages/basic`,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },

  getCurrentResidentStayDetails(residentId: number, isSpouse = false) {
    return async (dispatch: Dispatch) => {
      dispatch(
        actions.setResidentStayDetailsLoading({ loading: true, isSpouse }),
      );
      const response = await authGet(
        `/residents/${residentId}/current-resident-stay`,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          actions.setResidentStayDetailsLoading({ loading: false, isSpouse }),
        );
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      //add the residentID so other components won't need to fetch this info unnecessarily if the residentID matches
      const payload = { ...data, residentId, loading: false };
      if (isSpouse) {
        dispatch(actions.setResidentSpouseStayDetails(payload));
      } else {
        dispatch(actions.setResidentStayDetails(payload));
      }

      return { data };
    };
  },

  updateResidentStatus(residentId: number, status: string, isSpouse = false) {
    return async (dispatch: Dispatch) => {
      const response = await authPost(`/residents/${residentId}/status`, {
        residentStatus: status,
      });
      const { data, error } = response;

      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      if (isSpouse) {
        dispatch(actions.setSpouseOverview(data));
      } else {
        dispatch(actions.setResidentOverview(data));
      }

      return { data };
    };
  },

  saveClinicalData(residentId: number, payload: Payload) {
    return async (dispatch: Dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const { error, data } = await authPost(
        `residents/${residentId}/level-of-care`,
        payload,
      );
      dispatch(uiActions.setUILoading(false));
      if (error) {
        console.log(error.message);
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(uiActions.showNotification({ message: 'Changes saved' }));
      return { data };
    };
  },

  savePasrrData(residentId: number, payload: Payload) {
    return async (dispatch: Dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const { error, data } = await authPost(
        `residents/${residentId}/pasrr`,
        payload,
      );
      dispatch(uiActions.setUILoading(false));
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(uiActions.showNotification({ message: 'Changes saved' }));
      return { data };
    };
  },

  saveAuthData(residentId: number, payload: Payload) {
    return async (dispatch: Dispatch) => {
      dispatch(uiActions.setUILoading(true));
      const { error, data } = await authPost(
        `residents/${residentId}/auth`,
        payload,
      );
      dispatch(uiActions.setUILoading(false));
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(uiActions.showNotification({ message: 'Changes saved' }));
      return { data };
    };
  },

  getLatestAuth(residentId: number) {
    return async (dispatch: Dispatch) => {
      const { error, data } = await authGet(
        `residents/${residentId}/auth/latest`,
      );
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Auth details could not be retrieved',
          }),
        );
        return { error };
      }
      dispatch(residentActions.setResidentLatestAuth(data));
      return { data };
    };
  },

  getGlobalSearchResults(payload: Payload, apiUrl: string) {
    return async (dispatch: Dispatch) => {
      const { error, data } = await authGet([apiUrl, payload]);
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      return { data };
    };
  },

  getRFMSInfo(residentId: number) {
    return async (dispatch: Dispatch) => {
      const response = await authGet(`/residents/${residentId}/rfms-status`);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      dispatch(actions.setRFMSInfo(data));

      return { data };
    };
  },

  getCoverageGapBreakdown(residentId: number, isSpouse = false) {
    return async (dispatch: Dispatch) => {
      const response = await authGet(
        `/residents/${residentId}/coverages/gaps/breakdown`,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      if (isSpouse) {
        dispatch(actions.setSpouseCoverageGapBreakdown(data));
      } else {
        dispatch(actions.setResidentCoverageGapBreakdown(data));
      }

      return { data };
    };
  },

  getCoverageGapOverrides(residentId: number, isSpouse = false) {
    return async (dispatch: Dispatch) => {
      const response = await authGet(
        `/residents/${residentId}/coveragePeriodExceptions`,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      if (isSpouse) {
        dispatch(actions.setSpouseCoverageGapOverrides(data));
      } else {
        dispatch(actions.setResidentCoverageGapOverrides(data));
      }

      return { data };
    };
  },

  // TODO type ListFiltersType
  getResidentsBoard(filters: Record<string, any>, pageNumber = 1) {
    return async () => {
      const response = await authGet([
        '/residents/board',
        {
          pageNumber,
          resultsPerPage: 45,
          ...filters,
        },
      ]);
      return response;
    };
  },
};
