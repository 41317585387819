import { useCallback } from 'react';
import { parse, stringify } from 'query-string';
import { useNavigate } from 'react-router-dom';

export function useUpdatePageQueryParams<T>() {
  const navigate = useNavigate();

  return useCallback(
    /**
     *
     * @param {object} newQueryParams
     * @param {boolean} removeListsParams Set to true if navigating from one `List` component to another
     * to remove the old pagination.
     */
    (newQueryParams = {} as T, removeListsParams = false) => {
      const { pathname, search } = window.location;
      let currentParams = parse(search);
      if (removeListsParams) {
        const { rowsPerPage, page, orderBy, order, ...otherParams } =
          currentParams;
        currentParams = otherParams;
      }
      const newParams = { ...currentParams, ...newQueryParams };
      const query = Object.keys(newParams).length
        ? `?${stringify(newParams, { skipNull: true })}`
        : '';
      navigate(`${pathname}${query}`);
    },
    [navigate],
  );
}
