import React, { useCallback, useState, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { FileInput, TextInput, RadioInput } from '../common';
import { Box, Button, IconButton, Grid } from '@mui/material';
import {
  FileTypes,
  getFileExtension,
  getFileName,
  checkHasSetting,
  companySettingTypes,
} from '../../lib';
import { uiSelectors } from '../../state';
import { useNotify } from '../../hooks';
import { DocumentsList } from '../modals/DocumentsList';
import { DocumentViewModal } from '../modals/DocumentViewModal';
import { AddCircleIcon, CheckCircleIcon, CancelIcon } from '../../themes';
import { FormGrid } from './FormComponentsStyled';

const useStyles = makeStyles((theme) => ({
  addListItemButton: {
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.light,
    },
    marginBottom: 40,
  },
}));

export function DocumentUploader({
  disabled = false,
  documents,
  multiple = true,
  setDocuments,
  disableLink = false,
  hideHeader = false,
  useFormPadding = true,
  addToEMR,
}) {
  const classes = useStyles();

  const [previewFile, setPreviewFile] = useState(false);
  const notify = useNotify();
  const [openAddDocument, setOpenAddDoccument] = useState(disableLink);
  const [uploadType, setUploadType] = useState(uploadTypes.Upload);

  const companySettings = useSelector(uiSelectors.companySettings);
  const hasDefaultPushDocsToEMR = useMemo(
    () =>
      !!checkHasSetting(
        companySettings,
        companySettingTypes.DefaultPushDocsToEMR,
      ),
    [companySettings],
  );

  // eslint-disable-next-line
  const defaultLinkState = {
    customName: '',
    fileLocation: '',
    fileType: FileTypes.External,
  };
  const [linkState, setLinkState] = useState(defaultLinkState);

  const onUpload = useCallback(
    (uploads) => {
      if (!uploads || uploads.length < 0) {
        return;
      }

      //validate doc names, max length 50
      let numTruncated = 0;

      setDocuments([
        ...documents,
        ...uploads.map((u) => {
          const fileExtension = getFileExtension(u.name);
          let name = getFileName(u.name);
          if (name.length > 50) {
            name = name.substring(0, 50);
            numTruncated++;
          }
          return {
            ...u,
            file: u,
            fileExtension,
            name,
            previewURL: URL.createObjectURL(u),
            addToEMR: addToEMR && hasDefaultPushDocsToEMR, //use the setting for the default value if we might be showing the checkbox. the onSave function will handle whether it is actually showing or not (i.e. if its not showing, it will be set to false when saving)
          };
        }),
      ]);

      if (numTruncated > 0) {
        notify(
          `${numTruncated} file name${
            numTruncated > 1 ? 's were' : ' was'
          } truncated.  The maximum length for file names is 50.`,
          'warning',
        );
      }

      !disableLink && setOpenAddDoccument(false);
    },
    [
      documents,
      setDocuments,
      notify,
      disableLink,
      addToEMR,
      hasDefaultPushDocsToEMR,
    ],
  );

  const onCancelEdit = useCallback(() => {
    setOpenAddDoccument(false);
    setLinkState(defaultLinkState);
  }, [defaultLinkState, setOpenAddDoccument, setLinkState]);

  const onChangeLinkState = useCallback((e) => {
    const { target } = e;
    const value = target.value;
    const name = target.name;
    setLinkState((curr) => ({ ...curr, [name]: value }));
  }, []);

  const handleSaveLink = useCallback(() => {
    setDocuments([...documents, linkState]);
    onCancelEdit(); //clear it out
  }, [linkState, setDocuments, onCancelEdit, documents]);

  return (
    <>
      <DocumentsList
        documents={documents}
        onChange={setDocuments}
        genericDocList
        addToEMR={addToEMR}
        hideHeader={hideHeader}
        useFormPadding={useFormPadding}
      />
      {!openAddDocument ? (
        <Box paddingLeft={useFormPadding ? 4 : 0}>
          <Button
            className={classes.addListItemButton}
            startIcon={<AddCircleIcon />}
            onClick={() => setOpenAddDoccument(true)}
          >
            Add document
          </Button>
        </Box>
      ) : (
        <>
          {!disableLink && (
            <div
              style={{
                width: '100%',
                padding: useFormPadding && '0 24px',
              }}
            >
              <RadioInput
                name='uploadType'
                value={uploadType}
                onChange={(e) => setUploadType(e.target.value)}
                radios={[
                  { label: 'Upload', value: uploadTypes.Upload },
                  { label: 'Link', value: uploadTypes.Link },
                ]}
                labelProps={{ variant: 'description' }}
              />
            </div>
          )}
          {uploadType === uploadTypes.Upload ? (
            <FileInput
              customStyle={{
                border: '1px dashed #B9BDD4',
                backgroundColor: 'transparent',
                marginRight: useFormPadding && 32,
                marginLeft: useFormPadding && 32,
                width: useFormPadding ? 'calc(100% - 64)' : 'calc(100% - 32)',
              }}
              disabled={disabled}
              multiple={multiple}
              name='documents'
              onChange={onUpload}
            />
          ) : (
            uploadType === uploadTypes.Link && (
              <FormGrid
                container
                style={{ padding: useFormPadding && 24 }}
                spacing={2}
              >
                <Grid item xs={5}>
                  <TextInput
                    fullWidth
                    value={linkState.customName}
                    onChange={onChangeLinkState}
                    label='Document name'
                    name='customName'
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextInput
                    label='URL'
                    fullWidth
                    value={linkState.fileLocation}
                    name='fileLocation'
                    onChange={onChangeLinkState}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  <IconButton
                    onClick={handleSaveLink}
                    disabled={
                      linkState.fileLocation.length === 0 ||
                      linkState.customName.length === 0
                    }
                    size='large'
                  >
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton onClick={onCancelEdit} size='large'>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </FormGrid>
            )
          )}
        </>
      )}

      {!!previewFile && (
        <DocumentViewModal
          open={true}
          handleClose={() => setPreviewFile(null)}
          file={previewFile}
        />
      )}
    </>
  );
}

const uploadTypes = { Upload: 'upload', Link: 'link' };
