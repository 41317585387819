import { useMemo } from 'react';
import {
  checkHasSetting,
  companySettingTypes,
  TodoTypes,
  TodoTypeType,
  userTypes,
} from '../../../lib';
import { useGetUsersData } from '../../../hooks';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../../state';

export function useIsAllowedToMarkAsDone(
  todoType: TodoTypeType,
  taskAssignedToID: number | string,
  canDismiss?: boolean, //relevant for alerts
) {
  const { CompanyUserIdClaim, UserTypeClaim } = useGetUsersData();
  const companySettings = useSelector(uiSelectors.companySettings);

  const disableFromMarkingOtherTasks = useMemo(
    () =>
      UserTypeClaim === userTypes.Coordinator &&
      checkHasSetting(
        companySettings,
        companySettingTypes.DisableCoordinatorsFromMarkingOtherTasks,
      ),
    [UserTypeClaim, companySettings],
  );

  return (
    (todoType === TodoTypes.Alert && canDismiss) ||
    (todoType === TodoTypes.Task &&
      (!disableFromMarkingOtherTasks ||
        taskAssignedToID.toString() === CompanyUserIdClaim))
  );
}
