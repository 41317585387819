import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1040,
      lg: 1280,
      mdDesktop: 1400,
      xl: 1920,
    },
  },
  palette: {
    background: {
      // TODO when enough pages are redesigned, reassign 'default' to the 'new' color
      // and remove the hack in MainLayout that's manually setting the background color
      default: '#FFFFFF',
      new: '#F6F8FC',
    },
    primary: {
      main: '#096EF8',
      dark: '#003FED',
      light: '#428FFA',
      hover: '#096EF814', // 8%
      selected: '#096EF814', // 8%
      focus: '#096EF81F', // 12%
      focusVisible: '#096EF84D', // 30%
      outlinedBorder: '#096EF880', // 50%
    },
    secondary: {
      main: '#0514B3',
      light: '#0617CC',
      dark: '#04119A',
    },
    error: {
      main: '#EA070F',
      light: '#FA595F',
      dark: '#EA070F',
    },
    warning: {
      main: '#FE831A',
      light: '#FF9931',
      dark: '#FF6C01',
    },
    info: {
      main: '#FFBD07',
      light: '#FFD154',
      dark: '#F6A903',
    },
    success: {
      main: '#0FC076',
      light: '#11D885',
      dark: '#0DA867',
    },
    text: {
      primary: '#091B34',
      secondary: '#44516D',
      disabled: '#76849C',
    },
    action: {
      active: '#091B348F', // 56%
      hover: '#091B340A', // 4%
      selected: '#091B3414', // 8%
      focus: '#091B341F', // 12%
      disabled: '#091B3461', // 38%
      disabledBackground: '#091B341F', // 12%
      primaryColor: '#091B34',
      secondaryColor: '#44516D',
      disabledColor: '#76849C',
    },
    alert: {
      successBackground: '#ECFAF4',
      errorBackground: '#FEEDED',
      warningBackground: '#FEF2E7',
      infoBackground: '#EAF3FE',
      info: '#014361',
    },
    pink: {
      main: '#F940B3',
    },
    textTheme: {
      // TODO this should probably be phased out
      main: '#6C7493',
    },
    outlineTheme: {
      // TODO this should probably be phased out
      main: '#9DA7B9',
      light: '#D4D9E1',
    },
    default: {
      main: '#000',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'small',
      },
      styleOverrides: {
        root: {
          transition: 'all 300ms linear',
          borderColor: '#9DA7B9',
          borderRadius: '9px',
          height: 'fit-content',
          lineHeight: '22px',
          padding: '4px 10px',
        },
        outlinedPrimary: {
          color: '#000',
        },
        textPrimary: {
          color: '#000',
        },
      },
      variants: [
        {
          props: { size: 'smaller' },
          style: {
            fontSize: '10px',
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled svg.MuiSvgIcon-root': {
            color: '#091B3461',
          },
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'xsmall' },
          style: {
            fontSize: '1rem', // 16px
          },
        },
        {
          props: { fontSize: 'tiny' },
          style: {
            fontSize: '.875rem', // 14px
          },
        },
      ],
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderColor: '#9DA7B9',
          borderRadius: '9px',
          height: 'fit-content',
          lineHeight: '22px',
          padding: '4px 10px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#091B3429',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
        notchedOutline: {
          borderColor: '#8A91A3',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiCheckbox-root': {
            color: theme.palette.outlineTheme.main,
          },
          '&.MuiCheckbox-root.Mui-checked': {
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // really we only need this style applied to Dialogs (add '.MuiDialog-paper' to the specifier)
          // but many of our dialogs use our custom PaperComponent so have to apply this style to all Paper components
          '&.MuiPaper-root': {
            borderRadius: '9px',
          },
          // the borderRadius specified above should not apply to app bars such as the main app bar and the announcement banner
          '&.MuiPaper-root.MuiAppBar-root': {
            borderRadius: '0px',
          },
          // the borderRadius specified above should not apply to drawers such as the left sidebar and the announcements center
          '&.MuiPaper-root.MuiDrawer-paper': {
            borderRadius: '0px',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h4: {
      fontSize: '2.125rem', // 34px
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem', // 24px
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem', // 20px
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1rem', // 16px
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '.875rem', // 14px
      fontWeight: 600,
    },
    description: {
      fontSize: '.875rem', // 14px
      fontWeight: 500,
    },
    label: {
      fontSize: '.75rem', // 12px
      fontWeight: 500,
    },
    body0: {
      // TODO note this is defined as 'body1' in the UX library; it should be renamed when enough screens are redesigned
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    body1: {
      // TODO note this is defined as 'body2' in the UX library; it should be renamed when enough screens are redesigned
      fontSize: '.875rem', // 14px
      fontWeight: 400,
    },
    caption: {
      fontSize: '.75rem', // 12px
      fontWeight: 400,
    },
    caption2: {
      fontSize: '.625rem', // 10px
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    },
  },
});

/**** Add customizations to Typescript ****/

/** Typography **/
declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties;
    description: React.CSSProperties;
    body0: React.CSSProperties;
    caption2: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    description?: React.CSSProperties;
    body0?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    description: true;
    body0: true;
    caption2: true;
  }
}

/** Breakpoints **/
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mdDesktop: true;
  }
}

/** Size overrides **/
declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    smaller: true;
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xsmall: true;
    tiny: true;
  }
}

/** Component colors **/
declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    primary: true;
  }
}

/** Palette **/
declare module '@mui/material/styles' {
  // Add custom color tokens that can be used for palette colors defined as type PaletteColor
  interface PaletteColor {
    hover?: string;
    selected?: string;
    focus?: string;
    focusVisible?: string;
    outlinedBorder?: string;
  }
  interface SimplePaletteColorOptions {
    hover?: string;
    selected?: string;
    focus?: string;
    focusVisible?: string;
    outlinedBorder?: string;
  }

  // Add custom colors
  interface Palette {
    // assign the same types as 'primary':
    pink: Palette['primary'];
    textTheme: Palette['primary'];
    outlineTheme: Palette['primary'];
    default: Palette['primary'];
    // assign custom types:
    alert: TypeAlert;
  }
  interface PaletteOptions {
    // assign the same types as 'primary':
    pink?: PaletteOptions['primary'];
    textTheme?: PaletteOptions['primary'];
    outlineTheme?: PaletteOptions['primary'];
    default?: PaletteOptions['primary'];
    // assign custom types:
    alert?: TypeAlert;
  }

  // Add custom token to 'background' color
  interface TypeBackground {
    new: string;
  }

  // Add custom tokens to 'action' color
  interface TypeAction {
    primaryColor: string;
    secondaryColor: string;
    disabledColor: string;
  }

  // Define custom type with custom token for 'alert' color
  interface TypeAlert {
    successBackground: string;
    errorBackground: string;
    warningBackground: string;
    infoBackground: string;
    info: string;
  }
}
